import { Paper, Text, Title } from "@mantine/core";
import classes from "./CountStatsControls.module.css";

const CountStatsControls = ({ title, count, icon, verticalLineColor }) => {
  return (
    <Paper p="lg" className={classes.box} h={174} style={{ position: "relative" }}>
      <div
        className={classes.verticalLine}
        style={{ backgroundColor: verticalLineColor }}
      />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Title order={4} className={classes.title}>{title}</Title>
        {icon}
      </div>
      <Text className={classes.count}>
        {count}
      </Text>
    </Paper>
  );
};

export default CountStatsControls;