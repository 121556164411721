import { useState } from "react";
import {
  Menu,
  Group,
  ActionIcon,
  Image,
  Divider,
  ScrollArea,
} from "@mantine/core";

import WithPermission from "../../../features/auth/components/WithPermission/WithPermission";
import FilterCheckboxGroup from "../FilterCheckboxGroup/FilterCheckboxGroup";
import classes from "./FilterMenu.module.css";

export function FilterMenu({ form, filterOptions, onSubmit }) {
  const [opened, setOpened] = useState(false);

  const handleClose = () => {
    setOpened(false);
  };
  return (
    <Group justify="center">
      <Menu
        width={300}
        position="top-start"
        transitionProps={{ transition: "pop" }}
        withinPortal
        opened={opened}
        onClose={handleClose}
      >
        <Menu.Target>
          <ActionIcon
            variant={opened ? "outline" : "filled"}
            color={opened ? "orange" : "#F6F6F6"}
            radius={"md"}
            px="lg"
            py="md"
            aria-label="filter"
            onClick={() => setOpened((o) => !o)}
          >
            <img
              src={
                opened ? "/images/svg/color-sort.svg" : "/images/svg/sort.svg"
              }
            />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown
          className={classes.menu}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Group position="apart" px="xs" mt="xs">
            <span className={classes.heading}>Filter</span>
            <ActionIcon
              variant="transparent"
              onClick={handleClose}
              style={{ marginLeft: "auto" }}
            >
              <Image src="/images/svg/cross.svg" size={16} />
            </ActionIcon>
          </Group>
          <Divider mt="md" mb="sm" />
          <ScrollArea h={300}>
            <form
              onSubmit={form.handleSubmit((data) => {
                onSubmit(data);
              })}
              style={{ padding: "0 0.4rem 0 0.4rem" }}
            >
              {filterOptions.map((filter, index) => (
                <WithPermission key={index} roleRequired={filter.roles}>
                  <FilterCheckboxGroup
                    options={filter.options}
                    form={form}
                    name={filter.value}
                    type={filter.type}
                    label={filter.label}
                    className={classes.label}
                    onChange={form.handleSubmit((data) => {
                      onSubmit(data);
                    })}
                  />
                </WithPermission>
              ))}
            </form>
          </ScrollArea>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
