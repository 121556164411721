import axiosWebsitePrivate from "./axiosWebsitePrivate";

export const websiteFetcher = async (
  url,
  method = "GET",
  data = null,
  headers = {},
  options = {}
) => {
  if (!headers || Object.keys(headers).length === 0) {
    headers = { "content-type": "application/json" };
  }

  const config = {
    ...options,
    method,
    headers,
    data,
    responseType: options.responseType || "json",
  };

  const response = await axiosWebsitePrivate(url, config);
  return response.data;
};
