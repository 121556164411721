import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Select,
  SimpleGrid,
  NumberInput,
  Group,
  Switch,
  Button,
  Text,
} from "@mantine/core";

import { AsyncAutocomplete } from "../../../../common/components/AsyncAutocomplete/AsyncAutocomplete";

const AddViewEditWalletForm = ({ onSubmit, initialData, mode = "create" }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialData || {},
  });

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  const renderField = (label, value) => (
    <div>
      <Text fw={500} size="sm" mb={4}>
        {label}
      </Text>
      <Text>{value || "N/A"}</Text>
    </div>
  );

  if (mode === "view") {
    return (
      <SimpleGrid cols={2} spacing="lg" verticalSpacing="lg">
        {renderField("Balance", initialData?.balance)}
        {renderField("Wallet Status", initialData?.wallet_status)}
        {renderField("Currency Type", initialData?.currency_type)}
        {renderField("Locked", initialData?.is_locked ? "Yes" : "No")}
      </SimpleGrid>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid cols={2}>
        {mode === "create" && (
          <AsyncAutocomplete
            endpoint={"wallet/user-search/"}
            queryKey="query"
            control={control}
            name={"user"}
            getOptionValue={(item) => item.id.toString()}
            getOptionLabel={(item) => `${item.first_name} ${item.last_name}`}
            useWebsiteFetcher={true}
          />
        )}

        <Controller
          name="balance"
          control={control}
          rules={{ required: "Balance is required" }}
          render={({ field }) => (
            <NumberInput
              label="Balance"
              placeholder="Enter balance"
              decimalScale={2}
              allowNegative={false}
              hideControls
              error={errors.balance?.message}
              {...field}
            />
          )}
        />

        <Controller
          name="wallet_status"
          control={control}
          rules={{ required: "Wallet status is required" }}
          render={({ field }) => (
            <Select
              label="Wallet Status"
              placeholder="Select wallet status"
              data={[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
                { label: "Suspended", value: "suspended" },
              ]}
              error={errors.wallet_status?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          name="currency_type"
          control={control}
          rules={{ required: "Currency type is required" }}
          render={({ field }) => (
            <Select
              label="Currency Type"
              placeholder="Select Currency Type"
              data={["INR", "USD"]}
              error={errors.currency_type?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          name="is_locked"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              checked={field.value}
              label="Locked wallet"
              style={{
                marginTop: "36px",
                fontWeight: 600,
                color: "#333",
                fontSize: "0.9rem",
              }}
              onChange={(event) => {
                const value = event.currentTarget.checked;
                field.onChange(value);
              }}
            />
          )}
        />
      </SimpleGrid>

      <Group justify="center">
        <Button variant="gradient" type="submit" mt="xl" size="md">
          {mode === "create" ? "Create Wallet" : "Update Wallet"}
        </Button>
      </Group>
    </form>
  );
};

export default AddViewEditWalletForm;
