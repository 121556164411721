import ReactApexChart from "react-apexcharts";

const TravellersChart = ({ data }) => {
  const monthlyData = data?.monthly_data || [];

  const months = monthlyData.map((item) => item.month);
  const maleCounts = monthlyData.map((item) => item.data.male_policy_count);
  const femaleCounts = monthlyData.map((item) => item.data.female_policy_count);
  const totalCounts = monthlyData.map((item) => item.data.travellers_count);
  const domesticCounts = monthlyData.map((item) => item.data.domestic_policy_count);
  const internationalCounts = monthlyData.map((item) => item.data.international_policy_count);
  
  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    xaxis: {
      categories: months,
      // title: {
      //   text: "Months",
      // },
    },
    yaxis: {
      // title: {
      //   text: "Number of Policies",
      // },
    },
    grid: {
      show: true, 
      strokeDashArray: 4, 
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    colors: ["#1E90FF", "#FF69B4", "#32CD32", "#FFA500", "#8A2BE2"], 
  };

  
  const series = [
    {
      name: "Male Travelers",
      data: maleCounts,
    },
    {
      name: "Female Travelers",
      data: femaleCounts,
    },
    {
      name: "Total Travelers",
      data: totalCounts,
    },
    {
      name: "Domestic Policies",
      data: domesticCounts,
    },
    {
      name: "International Policies",
      data: internationalCounts,
    },
  ];

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <div>
      <ReactApexChart options={chartOptions} series={series} type="bar" height={350} />
    </div>
  );
};

export default TravellersChart;