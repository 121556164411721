import {
  useEffect,
  useState,
  useDeferredValue,
  useMemo,
  startTransition,
} from "react";
import useSWR from "swr";
import { Pagination, Flex } from "@mantine/core";
import { fetcher } from "../../../services/fetcher";
import { websiteFetcher } from "../../../features/website/services/fetcher";
import useFilters from "../../hooks/useFilters/useFilters";
import DataTable from "../DataTable/DataTable";

const DataWrapper = ({
  endpoint,
  config,
  filterOptions,
  sortOptions,
  onSelectHandler,
  useWebsiteFetcher = false,
}) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterQuery, setFilterQuery] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [orderingValue, setOrderingValue] = useState("");

  const memoizedConfig = useMemo(() => config, [config]);
  const memoizedFilterOptions = useMemo(() => filterOptions, [filterOptions]);
  const memoizedSortOptions = useMemo(() => sortOptions, [sortOptions]);

  const deferredSearchQuery = useDeferredValue(searchInputValue);

  const selectedFetcher = useWebsiteFetcher ? websiteFetcher : fetcher;

  const { form, onSubmit } = useFilters({
    onFilterChange: (query) => {
      setFilterQuery(query);
    },
  });

  const buildUrl = () => {
    let url = endpoint;
    const params = [];

    // Extract existing params if present in the URL
    const hasExistingParams = url.includes("?");

    if (filterQuery) {
      params.push(filterQuery.slice(1));
    }

    if (deferredSearchQuery) {
      params.push(`search=${encodeURIComponent(deferredSearchQuery)}`);
    }

    if (orderingValue) {
      params.push(`ordering=${encodeURIComponent(orderingValue)}`);
    }

    if (params.length > 0) {
      url += (hasExistingParams ? "&" : "?") + params.join("&");
    }

    if (!url.includes("page=")) {
      url +=
        (hasExistingParams || params.length > 0 ? "&" : "?") +
        `page=${pageIndex}`;
    }

    return url;
  };

  const fullUrl = buildUrl();

  const {
    data,
    error: pageError,
    isLoading,
  } = useSWR(fullUrl, selectedFetcher);

  useEffect(() => {
    if (data && data.count) {
      const totalItems = data.count;
      const itemsPerPage = 25;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
    }
  }, [data, setTotalPages]);

  useEffect(() => {
    setPageIndex(1);
  }, [deferredSearchQuery, filterQuery, orderingValue]);

  const handlePageChange = (page) => {
    startTransition(() => {
      setPageIndex(page);
    });
  };

  const handleSearch = (event) => {
    setSearchInputValue(event.target.value);
  };

  const handleOrdering = (value) => {
    startTransition(() => {
      setOrderingValue(value);
    });
  };

  const handleSelectionChange = (newSelection) => {
    onSelectHandler?.(newSelection);
  };

  if (pageError) {
    return <p>Something went wrong</p>;
  }

  return (
    <>
      <DataTable
        data={isLoading ? [] : data.results}
        config={memoizedConfig}
        filterOptions={memoizedFilterOptions}
        sortOptions={memoizedSortOptions}
        loading={isLoading}
        form={form}
        onSubmit={onSubmit}
        onSearch={handleSearch}
        searchValue={searchInputValue}
        onOrdering={handleOrdering}
        orderingValue={orderingValue}
        onSelectionChange={handleSelectionChange}
      />

      <Flex justify="end" mt="lg">
        <Pagination
          page={pageIndex}
          onChange={handlePageChange}
          total={totalPages}
          defaultValue={pageIndex}
          siblings={1}
        />
      </Flex>
    </>
  );
};

export default DataWrapper;
