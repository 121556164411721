import ReactApexChart from "react-apexcharts";

const VendorBarChart = ({ data }) => {
  if (!data) return <div>No data available</div>;

  // Extract months and vendor names
  const months = Object.keys(data).filter((key) => key !== "Year");
  const vendors = [
    ...new Set(
      months.flatMap((month) => Object.keys(data[month]))
    ),
  ];

  // Prepare data series for each month
  const series = months.map((month) => ({
    name: month,
    data: vendors.map((vendor) => data[month][vendor] || 0),
  }));

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        // endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      strokeDashArray: 4,
    },
    xaxis: {
      categories: vendors, 
      // title: {
      //   text: "Vendors",
      // },
    },
    yaxis: {
      // title: {
      //   text: "Policy Count",
      // },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} policies`,
      },
    },
  };

  return (
    <ReactApexChart
      options={chartOptions}
      series={series}
      type="bar"
      height={280}
    />
  );
};

export default VendorBarChart;