import { Table, Text, Center, Divider, Flex } from "@mantine/core";
import { usePaymentsData } from "../../hooks/usePaymentsData";
import { Link } from "react-router-dom";
import classes from "./PaymentDetails.module.css";

const PaymentDetails = ({ operatorEmail }) => {
  const { payments, paymentsError, isPaymentsLoading } = usePaymentsData(operatorEmail);

  if (isPaymentsLoading) {
    return <p>Loading...</p>;
  }

  if (paymentsError) {
    return <p>Error loading payments: {paymentsError.message}</p>;
  }

  if (!payments || payments.length === 0) {
    return (
      <>
        <Center>
            <Text size="lg" color="dimmed">
            Data not found
            </Text>
        </Center>
      </>
    );
  }

  return (
    <>
      <Text className={classes.planHeading} mb="md">
        Payment History
      </Text>   
      <Table>
        <thead>
          <tr>
            <th style={{ width: "70%" }} className={classes.subHeading}>Transaction ID</th>
            <th style={{ width: "25%" }} className={classes.subHeading}>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment, index) => (
            <tr key={payment.transaction_id} className={classes.row}>
                <td className={classes.data}>{index + 1}</td>
                <td className={classes.data}>{payment.transaction_id}</td>
                <td className={classes.amount}>{payment.total_amount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Divider />
        <Flex
            gap="md"
            justify="end"
            direction="row"
            wrap="wrap"
            mt="md"
      >
            <Link className={classes.button} to="/covers">View all</Link>
      </Flex>
    </>
  );
};

export default PaymentDetails;