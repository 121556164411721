import useAssignPlan from "../../hooks/useAssignPlan";
import { Table, Title, Divider, Flex } from "@mantine/core";
import { Link } from "react-router-dom";
import classes from "./AssignPlan.module.css";

const AssignPlan = ({ operatorEmail }) => {
  const { plans, plansError, isPlansLoading } = useAssignPlan(operatorEmail);

  if (isPlansLoading) {
    return <p>Loading...</p>;
  }

  if (plansError) {
    return <p>Error loading plans: {plansError.message}</p>;
  }

  const displayedPlans = plans?.customize_covers?.slice(0, 5);

  return (
    <>
        <Title className={classes.planHeading} mb="md">
            Active Plans
        </Title>
        <Table>
            <thead>
                <tr>
                    <th style={{ width: "5%" }}></th>
                    <th style={{ width: "70%" }} className={classes.subHeading}>Plan Title</th>
                    <th style={{ width: "25%" }} className={classes.subHeading}>Scope</th>
                </tr>
            </thead>
          
            <tbody>
                {displayedPlans?.map((plan, index) => (
                    <tr key={plan.id} className={classes.row}>
                        <td className={classes.data}>{index + 1}</td>
                        <td className={classes.data}>{plan.title}</td>
                        <td className={classes.amount}>{plan.cover_scope}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
        <Divider />
        <Flex
            gap="md"
            justify="end"
            direction="row"
            wrap="wrap"
            mt="md"
        >
            <Link className={classes.button} to="/covers">View all</Link>
        </Flex>
    </>
  );
};

export default AssignPlan;