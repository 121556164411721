import ReactApexChart from "react-apexcharts";
import { Paper } from "@mantine/core";

const TopOperatorsChart = ({ data }) => {
  // if (isLoading) {
  //   return <Loader />;
  // }

  // Function to truncate the operator name
  const truncateText = (text, maxLength = 10) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  const operatorNames = data?.length > 0
    ? data.map((op) => truncateText(op.operator_name)) 
    : ["No Data"];
  const policyCounts = data?.length > 0 ? data.map((op) => op.total_bookings) : [0];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    colors: ["#FF8A00"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
      },
    },
    dataLabels: {
      enabled: true,
    },
    grid: {
      show: true,
      strokeDashArray: 4,
    },
    xaxis: {
      categories: operatorNames,
      // title: { text: "Operators" },
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      // title: { text: "Total Bookings" },
    },
  };

  const series = [
    {
      name: "Total Bookings",
      data: policyCounts,
    },
  ];

  return (
    <Paper p="sm">
      <ReactApexChart options={options} series={series} type="bar" height={250} />
    </Paper>
  );
};

export default TopOperatorsChart;