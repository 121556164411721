import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import useSWR from "swr";
import { TextInput } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { fetcher } from "../../../../services/fetcher";

const DonutChart = ({ data = [], combinedTotal }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCover, setSelectedCover] = useState(null);
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 300);

  // API endpoints
  const searchCoverEndpoint = debouncedSearchQuery
    ? `dashboard/covers/search/?title=${debouncedSearchQuery}`
    : null;
  const selectedCoverEndpoint = selectedCover
    ? `dashboard/gateway-totals-amount/?cover_id=${selectedCover.id}`
    : null;

  // Fetch cover suggestions
  const { data: coverSuggestions } = useSWR(searchCoverEndpoint, fetcher, {
    revalidateOnFocus: false,
  });

  // Fetch selected cover data
  const { data: selectedCoverData } = useSWR(selectedCoverEndpoint, fetcher, {
    revalidateOnFocus: false,
  });

  // Extract data for chart
  const chartData = selectedCoverData?.gateway_totals || data || [];

  // Prepare series and labels for the chart
  const series = chartData.map((item) => item.total_amount_sum || 0);
  const labels = chartData.map((item) => item.gateway || "Unknown Gateway");

  const roundedTotal = combinedTotal ? combinedTotal.toFixed(2) : "0.00";

  // Chart options
  const chartOptions = {
    chart: {
      type: "donut",
    },
    labels,
    colors: ["#10D2DE", "#F7685B", "#29C370", "#FFB946"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
    position: "right", 
    offsetY: 40,
    offsetX: 10, 
    fontSize: "8px", 
    formatter: (seriesName, opts) => {
      const value = opts.w.globals.series[opts.seriesIndex];
      return `
        <div style="display: flex; justify-content: space-between; align-items: center; width: 150px;">
          <span style="text-align: left; color: #999; font-size: 8px; font-weight: 500;  text-transform: capitalize;">${seriesName}</span>
          <span style="text-align: right; color: #333; font-weight: bold;">₹${value.toLocaleString()}</span>
        </div>
      `;
    },
  },

  plotOptions: {
    pie: {
      donut: {
        size: "80%",
        labels: {
          show: true,
          name: {
            show: true,
          },
          value: {
            show: true,
            fontSize: "18px",
            fontWeight: "bold",
            color: "#333",
            formatter: () => `₹${roundedTotal}`, 
          },
          total: {
            show: true, // total value
          },
        },
      },
    },
  },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "8px",
        colors: ["#fff"],
      },
    },
  };

  return (
    <div>
    {/* <div>
        <strong>Total Amount:</strong> {combinedTotal}
      </div> */}
      {/* Search input */}
      <TextInput
        placeholder="Search Covers"
        value={selectedCover ? selectedCover.title : searchQuery}
        onChange={(e) => {
          const value = e.target.value;
          if (!selectedCover || value !== selectedCover.title) {
            setSearchQuery(value);
            if (value === "") {
              setSelectedCover(null);
            }
          }
        }}
        onFocus={() => {
          if (selectedCover) {
            setSearchQuery("");
          }
        }}
        style={{ marginBottom: "1rem", width: "300px" }}
      />

      {/* Display cover suggestions */}
      {coverSuggestions && (
        <div
          style={{
            position: "absolute",
            zIndex: 10,
            maxHeight: "150px",
            overflowY: "auto",
            backgroundColor: "#fff",
            border: "1px solid #ddd",
          }}
        >
          {coverSuggestions.map((cover) => (
            <div
              key={cover.id}
              style={{
                cursor: "pointer",
                padding: "0.5rem",
                borderBottom: "1px solid #ddd",
              }}
              onClick={() => {
                setSelectedCover(cover);
                setSearchQuery("");
              }}
            >
              {cover.title}
            </div>
          ))}
        </div>
      )}

      {/* Display donut chart */}
      {chartData.length > 0 ? (
        <ReactApexChart
          options={chartOptions}
          series={series}
          type="donut"
          height={250}
        />
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default DonutChart;