import { useState } from "react";

import { useDisclosure } from "@mantine/hooks";
import { Flex, Modal, Button } from "@mantine/core";

import { websiteFetcher } from "../../../website/services/fetcher";

import DataWrapper from "../../../../common/components/DataWrapper/DataWrapper";
import AddViewWalletTransactionForm from "../../forms/AddViewWalletTransactionForm/AddViewWalletTransactionForm";

import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";

const endpoint = "wallet/wallet-transactions/";

const WalletTransactions = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [isView, setIsView] = useState(null);
  const [selectTransaction, setSelectTransaction] = useState(null);

  const addTransactionMutation = async (transaction) => {
    try {
      await websiteFetcher(endpoint, "POST", transaction);
      successNotifications("Success! Transaction created");
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data.error, error);
    } finally {
      close();
    }
  };

  const onSubmit = (data) => {
    addTransactionMutation(data);
  };

  const handleViewTransaction = (transaction) => {
    setSelectTransaction(transaction);
    setIsView(true);
    open();
  };

  const config = {
    fields: [
      { key: "wallet_name", label: "Wallet User" },
      { key: "status", label: "Status" },
      { key: "amount", label: "Amount" },
      { key: "payment_mode", label: "Payment Mode" },
      { key: "transaction_number", label: "Transaction Number" },
      { key: "transaction_type", label: "Transaction type" },
      { key: "transaction_date", label: "Transaction Date", type: "datetime" },
      { key: "created_by_name", label: "Created by" },
    ],
    handler: [
      {
        label: "View",
        handler: (row) => handleViewTransaction(row),
      },
    ],
  };

  return (
    <>
      <Flex
        mih={50}
        gap="md"
        justify="end"
        align="center"
        direction="row"
        wrap="wrap"
        mb="lg"
      >
        <Button
          variant="outline"
          onClick={() => {
            setIsView(false);
            setSelectTransaction(null);
            open();
          }}
        >
          + Create Transcation
        </Button>
      </Flex>

      <DataWrapper
        endpoint={endpoint}
        config={config}
        useWebsiteFetcher={true}
      />

      <Modal
        opened={opened}
        onClose={() => {
          close();
        }}
        size="50rem"
        style={{ textTransform: "capitalize" }}
        centered
        title={(isView ? "View" : "Create") + " Transactions"}
        closeButtonProps={{
          icon: (
            <img
              src="/images/svg/close.svg"
              style={{ width: "1.6rem" }}
              alt="close-icon"
            />
          ),
        }}
      >
        <AddViewWalletTransactionForm
          onSubmit={onSubmit}
          initialData={selectTransaction}
          view={isView}
        />
      </Modal>
    </>
  );
};

export default WalletTransactions;
