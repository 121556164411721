
import useSWR from "swr";
import { fetcher } from "../../../services/fetcher";

export const usePaymentsData = (operatorEmail) => {
  const endpoint = `issuance/user-specific-payments/?user_email=${operatorEmail}`;
  const { data, error, isLoading } = useSWR(operatorEmail ? endpoint : null, fetcher);

  return {
    payments: data,
    paymentsError: error,
    isPaymentsLoading: isLoading,
  };
};
