import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Select,
  SimpleGrid,
  NumberInput,
  Group,
  Textarea,
  Button,
  Text,
} from "@mantine/core";

import { AsyncAutocomplete } from "../../../../common/components/AsyncAutocomplete/AsyncAutocomplete";

const AddViewWalletTransactionForm = ({
  onSubmit,
  initialData,
  view = null,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: initialData || {},
  });

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  const renderField = (label, value) => (
    <div>
      <Text fw={500} size="sm" mb={4}>
        {label}
      </Text>
      <Text>{value || "N/A"}</Text>
    </div>
  );

  if (view) {
    return (
      <SimpleGrid cols={2} spacing="lg" verticalSpacing="lg">
        {renderField("Wallet User", initialData?.wallet_name)}
        {renderField("Status", initialData?.status)}
        {renderField("Amount", initialData?.amount)}
        {renderField("Payment Mode", initialData?.payment_mode)}
        {renderField("Transaction Number", initialData?.transaction_number)}
        {renderField("Transaction type", initialData?.transaction_type)}
        {renderField("Transaction Date", initialData?.transaction_date)}
        {renderField("Created by", initialData?.created_by_name)}
        {renderField("Reference Number", initialData?.reference_number)}
        {renderField("Description", initialData?.description)}
      </SimpleGrid>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid cols={2}>
        <AsyncAutocomplete
          endpoint={"wallet/user-wallets/"}
          queryKey="query"
          control={control}
          name={"wallet"}
          getOptionValue={(item) => item.id.toString()}
          getOptionLabel={(item) => item.user_name}
          useWebsiteFetcher={true}
        />

        <Controller
          name="amount"
          control={control}
          rules={{ required: "Amount is required" }}
          render={({ field }) => (
            <NumberInput
              label="Amount"
              placeholder="Enter amount"
              decimalScale={2}
              allowNegative={false}
              hideControls
              error={errors.amount?.message}
              {...field}
            />
          )}
        />

        <Controller
          name="status"
          control={control}
          rules={{ required: "Status is required" }}
          render={({ field }) => (
            <Select
              label="Status"
              placeholder="Select status"
              data={[
                { label: "Pending", value: "PENDING" },
                { label: "Completed", value: "COMPLETED" },
                { label: "Failed", value: "FAILED" },
              ]}
              error={errors.status?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          name="payment_mode"
          control={control}
          rules={{ required: "Payment mode is required" }}
          render={({ field }) => (
            <Select
              label="Payment mode"
              placeholder="Select payment mode"
              data={[
                { label: "Cash", value: "CASH" },
                { label: "Card", value: "CARD" },
                { label: "UPI", value: "UPI" },
                { label: "Commission", value: "COMMISSION" },
                { label: "Bank Transfer", value: "BANK_TRANSFER" },
                { label: "Online Payment", value: "ONLINE_PAYMENT" },
                { label: "Other", value: "OTHER" },
              ]}
              error={errors.payment_mode?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          name="transaction_type"
          control={control}
          rules={{ required: "Transaction type is required" }}
          render={({ field }) => (
            <Select
              label="Transaction type"
              placeholder="Select transaction type"
              data={[
                { label: "Add", value: "ADD" },
                { label: "Deduct", value: "DEDUCT" },
                { label: "Cancel", value: "CANCEL" },
              ]}
              error={errors.transaction_type?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          name="reference_number"
          control={control}
          rules={{ required: "Reference number is required" }}
          render={({ field }) => (
            <NumberInput
              label="Reference Number"
              placeholder="Enter reference number"
              decimalScale={2}
              allowNegative={false}
              hideControls
              error={errors.reference_number?.message}
              {...field}
            />
          )}
        />

        <Textarea
          label="Description"
          placeholder="Enter payment note if any"
          {...register("description")}
        />
      </SimpleGrid>

      <Group justify="center">
        <Button variant="gradient" type="submit" mt="xl" size="md">
          {"Create Transaction"}
        </Button>
      </Group>
    </form>
  );
};

export default AddViewWalletTransactionForm;
