import ReactApexChart from "react-apexcharts";

const BarChart = ({ data }) => {
  // Extract and format the data for the chart
  const categories = data ? data.map(item => item.nationality) : [];
  const seriesData = data ? data.map(item => item.traveller_count) : [];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    colors: ["#4F52F9", "#DE7BFF", "#21E6B8", "#004A8F", "#F57E2E"],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "20%",
        distributed: true, 
      },
    },
    dataLabels: {
      enabled: true,
    },
    grid: {
      show: true, 
      strokeDashArray: 4, 
    },
    xaxis: {
      categories: categories,
      // title: {
      //   text: "Number of Travellers",
      // },
    },
    yaxis: {
      // title: {
      //   text: "Nationality",
      // },
    },
  };

  const series = [
    {
      name: "Travellers",
      data: seriesData,
      color: "#4F52F9", 
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={300} />
    </div>
  );
};

export default BarChart;