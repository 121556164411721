import { useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import useSWR from "swr";
import dayjs from "dayjs";
import { fetcher } from "../../../../services/fetcher";
import FilterMenu from "../FilterMenu/FilterMenu";
import TripStats from "../../containers/TripStats/TripStats";
import { Grid, GridCol, Paper, TextInput } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { USER_TYPES } from "../../../../utils/userTypes";
import MainLoader from "../../../../common/components/Loader/Loader";
import classes from "./ColumnChart.module.css";

const TripStatusChart = () => {
  const { user_type } = useSelector((state) => state.auth);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCover, setSelectedCover] = useState(null);
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 300);

  const updateFilter = (newFilter) => {
    setFilters((prev) => ({ ...prev, ...newFilter }));
  };

  const getEndpoint = (coverType) => {
    const queryParams = [];
    if (filters.startDate && filters.endDate) {
      queryParams.push(
        `filter_type=custom`,
        `start_date=${dayjs(filters.startDate).format("YYYY-MM-DD")}`,
        `end_date=${dayjs(filters.endDate).format("YYYY-MM-DD")}`
      );
    }
    queryParams.push(`cover_type=${coverType}`);
    return `dashboard/trip-status/?${queryParams.join("&")}`;
  };

  // Check for both SUPERUSER and OPERATOR
  const domesticEndpoint =
    user_type[0] === USER_TYPES.SUPERUSER || user_type[0] === USER_TYPES.OPERATOR
      ? getEndpoint("Domestic")
      : null;
  const internationalEndpoint =
    user_type[0] === USER_TYPES.SUPERUSER || user_type[0] === USER_TYPES.OPERATOR
      ? getEndpoint("International")
      : null;

  const { data: domesticData, isLoading: loadingDomestic } = useSWR(domesticEndpoint, fetcher);
  const { data: internationalData, isLoading: loadingInternational } = useSWR(internationalEndpoint, fetcher);

  const searchCoverEndpoint =
    selectedCover && (user_type[0] === USER_TYPES.SUPERUSER || user_type[0] === USER_TYPES.OPERATOR)
      ? `dashboard/trip-status/?cover_id=${selectedCover.id}`
      : null;

  const { data: searchCoverData, isLoading: loadingSearchCover } = useSWR(
    searchCoverEndpoint,
    fetcher,
    { revalidateOnFocus: false }
  );

  const { data: coverSuggestions } = useSWR(
    debouncedSearchQuery && (user_type[0] === USER_TYPES.SUPERUSER || user_type[0] === USER_TYPES.OPERATOR)
      ? `dashboard/covers/search/?title=${debouncedSearchQuery}`
      : null,
    fetcher,
    { revalidateOnFocus: false }
  );

  if (loadingDomestic || loadingInternational || (searchCoverEndpoint && loadingSearchCover))
    return <div><MainLoader /></div>;

  // Prepare data for the chart
  const categories = ["Active", "Yet to Active", "Matured", "Pending"];
  const series = selectedCover
    ? [
        {
          name: selectedCover.title,
          data: categories.map((status) => searchCoverData?.[status.toLowerCase()] || 0),
        },
      ]
    : [
        {
          name: "Domestic",
          data: categories.map((status) => domesticData?.[status.toLowerCase()] || 0),
        },
        {
          name: "International",
          data: categories.map((status) => internationalData?.[status.toLowerCase()] || 0),
        },
      ];

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    xaxis: {
      categories,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    legend: {
      position: "top",
    },
    grid: {
      show: true,
      strokeDashArray: 4,
    },
    colors: selectedCover ? ["#00C2FF"] : ["#FF8A00", "#00C2FF"],
  };

  return (
    <div>
      <Grid>
        <GridCol span={{ base: 12, md: 6, lg: 6 }}>
          <Paper className={classes.tripChart}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <TextInput
                placeholder="Search Covers"
                value={selectedCover ? selectedCover.title : searchQuery}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!selectedCover || value !== selectedCover.title) {
                    setSearchQuery(value);
                    if (value === "") {
                      setSelectedCover(null);
                    }
                  }
                }}
                onFocus={() => {
                  if (selectedCover) {
                    setSearchQuery("");
                  }
                }}
                style={{ width: "300px" }}
              />
              {coverSuggestions && (
                <div
                  style={{
                    position: "absolute",
                    top: "18rem",
                    left: "20rem",
                    zIndex: 10,
                    maxHeight: "70px",
                    overflowY: "auto",
                  }}
                >
                  {coverSuggestions.map((cover) => (
                    <div
                      key={cover.id}
                      style={{
                        cursor: "pointer",
                        padding: "0.5rem",
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        fontSize: "10px",
                      }}
                      onClick={() => {
                        setSelectedCover(cover);
                        setSearchQuery("");
                      }}
                    >
                      {cover.title}
                    </div>
                  ))}
                </div>
              )}
              <FilterMenu
                startDate={filters.startDate}
                endDate={filters.endDate}
                setStartDate={(date) => updateFilter({ startDate: date })}
                setEndDate={(date) => updateFilter({ endDate: date })}
                hideMonthYear
              />
            </div>
            <Chart options={chartOptions} series={series} type="bar" height={250} />
          </Paper>
        </GridCol>
        <GridCol span={{ base: 12, md: 6, lg: 6 }}>
          <TripStats
            domesticData={domesticData}
            internationalData={internationalData}
            selectedCoverData={searchCoverData}
            selectedCover={selectedCover}
          />
        </GridCol>
      </Grid>
    </div>
  );
};

export default TripStatusChart;