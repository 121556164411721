import { useState, useEffect, startTransition, useCallback } from "react";
import useSWR from "swr";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Flex, Modal, Button, Pagination } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { fetcher } from "../../../services/fetcher";

import BookingForm from "../forms/BookingForm/BookingForm";
import FormDataTable from "../../../common/components/FormDataTable/FormDataTable";

import {
  errorNotifications,
  successNotifications,
} from "../../../utils/handleNotifications";
import { USER_TYPES } from "../../../utils/userTypes";
import {
  BookingStatus,
  BookingPriority,
  PaymentStatus,
  DocumentStatus,
  BookingReceived,
} from "../utils/bookingOptions";

const endpoint = "asc-booking/bookings/";

const Bookings = () => {
  const { roles } = useSelector((state) => state.auth);
  const [opened, { open, close }] = useDisclosure(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { control, handleSubmit } = useForm();

  const { data, isLoading, mutate } = useSWR(endpoint, fetcher);

  const { data: userProfiles, isLoading: isProfileLoading } = useSWR(
    "auth-api/user-create/",
    fetcher
  );

  const addBookingMutation = async (booking) => {
    try {
      await fetcher(endpoint, "POST", booking);
      successNotifications("Success! Booking added");
      close();
    } catch (error) {
      console.log(error);
      errorNotifications(error.response.data, error);
    }
  };

  const updateBookingMutation = async (payload) => {
    try {
      await fetcher(`${endpoint}${payload.id}/`, "PATCH", payload);
      mutate();
      successNotifications(`Success!`);
    } catch (error) {
      console.log(error);
      errorNotifications(error.response.data, error);
    }
  };

  useEffect(() => {
    if (data && data.count) {
      const totalItems = data.count;
      const itemsPerPage = 25;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
    }
  }, [data, setTotalPages]);

  const handlePageChange = (page) => {
    startTransition(() => {
      setPageIndex(page);
    });
  };

  const onSubmit = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (key !== "csv_file" && key !== "payment_receipt") {
        formData.append(key, data[key]);
      }
    });

    if (data.csv_file) {
      const csvBlob = new Blob([data.csv_file], { type: "text/csv" });
      formData.append("csv_file", csvBlob);
    }

    if (data.payment_receipt) {
      const receiptBlob = new Blob([data.payment_receipt], {
        type: "application/pdf",
      });
      formData.append("payment_receipt", receiptBlob);
    }

    console.log("JSON Data", data);

    for (const pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }

    addBookingMutation(formData);
  };

  const onSubmitRow = (
    row,
    fieldKeys = ["booking_received", "issuance_representative"]
  ) => {
    handleSubmit((formData) => {
      const coverID = row.id;

      const data = fieldKeys.reduce((acc, key) => {
        const value = formData[`${key}_${row.id}`];
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {});

      data.id = coverID;

      updateBookingMutation(data);
    })();
  };

  const selectOptions = isProfileLoading ? [] : userProfiles;

  const getOptionValue = useCallback(
    (item) => item.user_profile_info.id.toString(),
    []
  );

  const getOptionLabel = useCallback(
    (item) => item.user_profile_info.user.first_name,
    []
  );

  const config = {
    fields: [
      { key: "booking_no", label: "Booking no." },
      {
        key: "priority",
        label: "Booking Priority",
        isSelect: true,
        options: BookingPriority,
      },
      { key: "plan_name", label: "Cover Name" },
      { key: "days", label: "Days" },
      { key: "premium_amount", label: "Amount" },
      { key: "discount", label: "Discount" },
      { key: "start_date", label: "Start Date" },
      { key: "end_date", label: "End Date" },
      {
        key: "booking_status",
        label: "Booking status",
        isSelect: true,
        options: BookingStatus,
      },
      { key: "client_or_operator", label: "Client or Operator" },
      {
        key: "booking_received",
        label: "Booking received",
        isSelect: true,
        options: BookingReceived,
      },
      {
        key: "issuance_representative",
        label: "Issuance Representative",
        isEnhanceSelect: true,
        options: selectOptions,
        getOptionValue: getOptionValue,
        getOptionLabel: getOptionLabel,
      },
      {
        key: "payment_status",
        label: "Payment Status",
        isSelect: true,
        options: PaymentStatus,
      },
      {
        key: "document_status",
        label: "Document Status",
        isSelect: true,
        options: DocumentStatus,
      },
      { key: "event_location", label: "Event location" },
      { key: "remarks", label: "Remarks" },
    ],
  };

  return (
    <>
      <Flex
        mih={50}
        gap="md"
        justify="end"
        align="center"
        direction="row"
        wrap="wrap"
        mb="lg"
      >
        <Button
          variant="outline"
          onClick={() => {
            open();
          }}
        >
          + Create Booking
        </Button>
      </Flex>

      <FormDataTable
        data={isLoading ? [] : data}
        config={config}
        loading={isLoading}
        onSubmitRow={onSubmitRow}
        control={control}
        userType={
          roles.includes(USER_TYPES.SUPERUSER) ? USER_TYPES.SUPERUSER : null
        }
      />

      <Flex justify="end" mt="lg">
        <Pagination
          page={pageIndex}
          onChange={handlePageChange}
          total={totalPages}
          defaultValue={pageIndex}
          siblings={1}
        />
      </Flex>

      <Modal
        opened={opened}
        onClose={() => {
          close();
        }}
        size="50rem"
        style={{ textTransform: "capitalize" }}
        centered
        title={"Transactions"}
        closeButtonProps={{
          icon: (
            <img
              src="/images/svg/close.svg"
              style={{ width: "1.6rem" }}
              alt="close-icon"
            />
          ),
        }}
      >
        <BookingForm
          onSubmit={onSubmit}
          userProfiles={isProfileLoading ? [] : userProfiles}
        />
      </Modal>
    </>
  );
};

export default Bookings;
