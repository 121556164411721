import { useState, useCallback, useTransition } from "react";
import { FloatingIndicator, Tabs, Skeleton } from "@mantine/core";
import { fetcher } from "../../../../services/fetcher";

import DataWrapper from "../../../../common/components/DataWrapper/DataWrapper";
import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";
import { filterOptions, sortOptions } from "../../utils/travellers";
import classes from "./Travellers.module.css";

const tabConfig = [
  { value: "1", label: "All", endpoint: "issuance/policies/all/travellers/" },
  {
    value: "2",
    label: "Website",
    endpoint: "issuance/policies/website/travellers/",
  },
  {
    value: "3",
    label: "Operator",
    endpoint: "issuance/policies/operator/travellers/",
  },
  {
    value: "4",
    label: "Issued",
    endpoint: "issuance/policies/issued/travellers/",
  },
  {
    value: "5",
    label: "Unissued",
    endpoint: "issuance/policies/unissued/travellers/",
  },
];

const Travellers = () => {
  const [rootRef, setRootRef] = useState(null);
  const [value, setValue] = useState("1");
  const [controlsRefs, setControlsRefs] = useState({});
  const [currentTab, setCurrentTab] = useState("1");
  const [isPending, startTransition] = useTransition();
  const [currentSelection, setCurrentSelection] = useState([]);

  const setControlRef = (val) => (node) => {
    controlsRefs[val] = node;
    setControlsRefs(controlsRefs);
  };

  const handleTabChange = (newValue) => {
    startTransition(() => {
      setValue(newValue);
      setCurrentTab(newValue);
    });
  };

  const handleSelection = (newSelection) => {
    setCurrentSelection(newSelection);
  };

  const handleTravellerDetails = (row) => {
    return `travellers/${row.id}`;
  };

  const handleEndorsementDetails = (row) => {
    return `/issuance/endorsement/${row.id}`;
  };

  const handleResendDoc = useCallback(async (row) => {
    const { id: traveller_id } = row;

    const payload = {
      traveller_id: traveller_id,
    };

    try {
      await fetcher("issuance/generate-or-send-document/", "POST", payload);
      successNotifications("Success! Document resent");
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data.error, error);
    }
  }, []);

  const handleDownloadDoc = useCallback(async (row) => {
    try {
      const { cover_document: downloadUrl } = row;

      if (
        !downloadUrl ||
        !downloadUrl.startsWith("https://asc360.s3.amazonaws.com/")
      ) {
        successNotifications("Download URL is not available.");
        return;
      }

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "CoverDoc";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      successNotifications("Error downloading document:", error);
    }
  }, []);

  const handleTravellersExport = async () => {
    if (currentSelection.length === 0) {
      return successNotifications("Please select travellers");
    }

    const payload = { traveller_ids: currentSelection };

    try {
      const response = await fetcher(
        "issuance/export/selected-travellers/",
        "POST",
        payload
      );

      if (response) {
        const csvBlob = new Blob([response], { type: "text/csv" });

        const url = window.URL.createObjectURL(csvBlob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = "exported_travellers.csv";

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        successNotifications("Travellers exported successfully");
      } else {
        throw new Error("Export failed");
      }
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  const travellersConfig = {
    fields: [
      { key: "cover_number", label: "Cover Number" },
      { key: "cover_type", label: "Cover Type" },
      { key: "cover_name", label: "Cover Name" },
      { key: "email", label: "Traveller Email" },
      { key: "first_name", label: "Traveller Name" },
      { key: "nationality_name", label: "Nationality" },
      { key: "dob", label: "Date of birth" },
      { key: "cover_issued_dt", label: "Issued date", type: "datetime" },
      { key: "created_on", label: "Booking date", type: "datetime" },
      { key: "created_by_name", label: "Issued by" },
      { key: "policy_status_traveller_wise", label: "Status", type: "status" },
    ],
    handler: [
      {
        label: "Resend",
        handler: (row) => handleResendDoc(row),
      },
      {
        label: "Cover Doc",
        handler: (row) => handleDownloadDoc(row),
      },
    ],
    actions: [
      {
        label: "Details",
        to: (row) => handleTravellerDetails(row),
        isButton: true,
      },
      {
        label: "Endorsement",
        to: (row) => handleEndorsementDetails(row),
      },
    ],
    selectionCheckbox: true,
    selectionActions: [{ label: "Export", handler: handleTravellersExport }],
  };

  return (
    <Tabs variant="none" value={value} onChange={handleTabChange}>
      <Tabs.List ref={setRootRef} className={classes.list}>
        {tabConfig.map((tab) => (
          <Tabs.Tab
            key={tab.value}
            value={tab.value}
            ref={setControlRef(tab.value)}
            className={classes.tab}
          >
            {tab.label}
          </Tabs.Tab>
        ))}

        <FloatingIndicator
          target={value ? controlsRefs[value] : null}
          parent={rootRef}
          className={classes.indicator}
        />
      </Tabs.List>

      {isPending ? (
        <Skeleton visible={isPending} height={600}></Skeleton>
      ) : (
        tabConfig.map((tab) =>
          tab.value === currentTab ? (
            <Tabs.Panel key={tab.value} value={tab.value}>
              <DataWrapper
                filterOptions={filterOptions}
                endpoint={tab.endpoint}
                config={travellersConfig}
                sortOptions={sortOptions}
                onSelectHandler={handleSelection}
              />
            </Tabs.Panel>
          ) : null
        )
      )}
    </Tabs>
  );
};

export default Travellers;
