import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Select,
  SimpleGrid,
  Group,
  Button,
  Text,
  Textarea,
} from "@mantine/core";

const AddViewEditFaqForm = ({ onSubmit, initialData, mode = "create" }) => {
  const {
    handleSubmit,
    control,
    reset,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: initialData || {},
  });

  useEffect(() => {
    if (initialData) {
      const modifiedData = {
        ...initialData,
        category: String(initialData.category),
      };
      reset(modifiedData);
    }
  }, [initialData, reset]);

  const renderField = (label, value) => (
    <div>
      <Text fw={500} size="sm" mb={4}>
        {label}
      </Text>
      <Text>{value || "N/A"}</Text>
    </div>
  );

  if (mode === "view") {
    return (
      <SimpleGrid cols={1} spacing="lg" verticalSpacing="lg">
        {renderField("Category", initialData?.category_display)}
        {renderField("Question", initialData?.question)}
        {renderField("Answer", initialData?.answer)}
      </SimpleGrid>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid cols={1}>
        <Controller
          name="category"
          control={control}
          rules={{ required: "Faq category is required" }}
          render={({ field }) => (
            <Select
              label="Faq category"
              withAsterisk
              placeholder="Select faq category"
              data={[
                { label: "Eligibility", value: "1" },
                { label: "Claims", value: "2" },
                { label: "Trip", value: "3" },
                { label: "Troubleshooting", value: "4" },
                { label: "Comments", value: "5" },
              ]}
              error={errors.wallet_status?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Textarea
          label="Question"
          withAsterisk
          placeholder="Enter question of faq"
          {...register("question")}
        />

        <Textarea
          label="Answer"
          withAsterisk
          placeholder="Enter answer of faq"
          {...register("answer")}
        />
      </SimpleGrid>

      <Group justify="center">
        <Button variant="gradient" type="submit" mt="xl" size="md">
          {"Submit"}
        </Button>
      </Group>
    </form>
  );
};

export default AddViewEditFaqForm;
