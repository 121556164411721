import { Grid, GridCol, Card, Text, Image } from "@mantine/core";
import classes from "./TripStats.module.css";

const TripStats = ({ domesticData, internationalData, selectedCoverData, selectedCover }) => {
  const categories = ["Active", "Yet to Active", "Matured", "Pending"];
  const images = {
    Active: "/images/svg/active-policy.svg", 
    "Yet to Active": "/images/svg/inactive-policy.svg",
    Matured: "/images/svg/matured-policy.svg",
    Pending: "/images/svg/pending-policy.svg",
  };
  const lineColors = {
    Active: "#4caf50", 
    "Yet to Active": "#f44336", 
    Matured: "#2196f3", 
    Pending: "#ff9800",  
  };

  const combinedStats = categories.reduce((acc, status) => {
    if (selectedCover && selectedCoverData) {
      acc[status] = selectedCoverData[status.toLowerCase()] || 0;
    } else {
      const domesticValue = domesticData?.[status.toLowerCase()] || 0;
      const internationalValue = internationalData?.[status.toLowerCase()] || 0;
      acc[status] = domesticValue + internationalValue;
    }
    return acc;
  }, {});

  return (
    <Grid spacing="sm">
      {categories.map((status) => (
        <GridCol span={6} key={status}>
          <Card className={classes.cardStyle} h={"174"}>
            <div className={classes.verticalLine} style={{ backgroundColor: lineColors[status] }}></div>
            <div className={classes.card}>
              <Text className={classes.title}>{status}</Text>
                <Image
                  src={images[status]} 
                  alt={`${status} icon`}
                  height={30}
                  fit="contain"
                />
            </div>
            <Text className={classes.statsTitle}>
              {combinedStats[status]}
            </Text>
          </Card>
        </GridCol>
      ))}
    </Grid>
  );
};

export default TripStats;