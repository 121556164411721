import { useState, useEffect } from "react";
import useSWR from "swr";

import { useDisclosure, useDebouncedValue } from "@mantine/hooks";
import {
  SimpleGrid,
  Card,
  Group,
  Badge,
  Text,
  Flex,
  Modal,
  Button,
  Pagination,
  TextInput,
} from "@mantine/core";

import { websiteFetcher } from "../../../website/services/fetcher";

import AddViewEditWalletForm from "../../forms/AddViewEditWalletForm/AddViewEditWalletForm";
import WalletCard from "../../../../common/components/WalletCard/WalletCard";
import MainLoader from "../../../../common/components/Loader/Loader";

import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";

const endpoint = "wallet/user-wallets/";
const searchEndpoint = "wallet/wallet-search-filter/";

const UserWallets = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [mode, setMode] = useState("create");

  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [search, setSearch] = useState("");
  const [debouncedQuery] = useDebouncedValue(search, 400);

  const currentEndpoint = debouncedQuery
    ? `${searchEndpoint}?search=${debouncedQuery}&page=${pageIndex}`
    : `${endpoint}?page=${pageIndex}`;

  const { data, isLoading, mutate } = useSWR(currentEndpoint, websiteFetcher);

  useEffect(() => {
    if (data && data.count) {
      const totalItems = data.count;
      const itemsPerPage = 25;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
    }
  }, [data, setTotalPages]);

  const handleWalletAction = async (fund, mode) => {
    try {
      const url =
        mode === "create" ? endpoint : `${endpoint}${selectedWallet.user}/`;

      const method = mode === "create" ? "POST" : "PATCH";

      await websiteFetcher(url, method, fund);

      successNotifications(
        `Success! Wallet ${mode === "create" ? "Created" : "Updated"}`
      );
      mutate();
      close();
    } catch (error) {
      console.error(error);
      errorNotifications(error.response?.data, error);
    } finally {
      setSelectedWallet(null);
      setMode("create");
    }
  };

  const onSubmit = (data) => {
    const mode = selectedWallet ? "edit" : "create";
    handleWalletAction(data, mode);
  };

  const handleViewWallet = (wallet) => {
    setSelectedWallet(wallet);
    setMode("view");
    open();
  };

  const handleEditWallet = (wallet) => {
    setSelectedWallet(wallet);
    setMode("edit");
    open();
  };

  const handlePageChange = (page) => {
    setPageIndex(page);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setPageIndex(1);
  };

  if (isLoading) {
    return <MainLoader />;
  }

  return (
    <>
      <Flex
        mih={50}
        gap="md"
        justify="space-between"
        align="center"
        direction="row"
        wrap="wrap"
        mb="lg"
      >
        <TextInput
          placeholder="Search by user name or email"
          value={search}
          onChange={handleSearch}
        />

        <Button
          variant="outline"
          onClick={() => {
            setSelectedWallet(null);
            open();
          }}
        >
          + Create Wallet
        </Button>
      </Flex>

      <SimpleGrid cols={3}>
        {data?.results.map((wallet) => (
          <Card shadow="sm" padding="lg" key={wallet.id} radius="md" withBorder>
            <Group justify="space-between" mb="xs">
              <Text fw={500}>{wallet.user_name}</Text>
              <Badge color="green">{wallet.wallet_status}</Badge>
            </Group>

            <WalletCard balance={wallet.balance} type={wallet.currency_type} />

            <Group justify="space-between" mt="xs">
              <Button
                variant="outline"
                onClick={() => handleViewWallet(wallet)}
              >
                View
              </Button>
              <Button
                variant="outline2"
                onClick={() => handleEditWallet(wallet)}
              >
                Edit
              </Button>
            </Group>
          </Card>
        ))}
      </SimpleGrid>

      <Flex justify="end" mt="lg">
        <Pagination
          page={pageIndex}
          onChange={handlePageChange}
          total={totalPages}
          defaultValue={pageIndex}
          siblings={1}
        />
      </Flex>

      <Modal
        opened={opened}
        onClose={() => {
          close();
          setSelectedWallet(null);
          setMode("create");
        }}
        size="50rem"
        style={{ textTransform: "capitalize" }}
        centered
        title={
          mode === "create"
            ? "Create Wallet"
            : `${mode} ${selectedWallet?.user_name} wallet`
        }
        closeButtonProps={{
          icon: (
            <img
              src="/images/svg/close.svg"
              style={{ width: "1.6rem" }}
              alt="close-icon"
            />
          ),
        }}
      >
        <AddViewEditWalletForm
          onSubmit={onSubmit}
          initialData={selectedWallet}
          mode={mode}
        />
      </Modal>
    </>
  );
};

export default UserWallets;
