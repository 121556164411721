import { useState, useCallback } from "react";

import { websiteFetcher } from "../../../../services/fetcher";

import { Flex, Modal, Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import DataWrapper from "../../../../../../common/components/DataWrapper/DataWrapper";
import AddViewEditPromotionForm from "../../forms/AddViewEditPromotionForm/AddViewEditPromotionForm";
import ConfirmModal from "../../../../../../common/components/ConfirmModal/ConfirmModal";

import {
  successNotifications,
  errorNotifications,
} from "../../../../../../utils/handleNotifications";

const endpoint = "covers/promotions/";

const PromotionList = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [openedDelete, { open: openDelete, close: closeDelete }] =
    useDisclosure(false);

  const [selectPromotion, setSelectPromotion] = useState(null);
  const [mode, setMode] = useState(null);

  const handlePromotionAction = async (data, mode) => {
    try {
      const url =
        mode === "create" ? endpoint : `${endpoint}${selectPromotion.id}/`;

      const method = mode === "create" ? "POST" : "PATCH";

      await websiteFetcher(url, method, data);

      successNotifications(
        `Success! Promotion ${mode === "create" ? "Created" : "Updated"}`
      );
      close();
    } catch (error) {
      console.error(error);
      errorNotifications(error.response?.data, error);
    } finally {
      setSelectPromotion(null);
      setMode("create");
    }
  };

  const onSubmit = (data) => {
    const mode = selectPromotion ? "edit" : "create";
    handlePromotionAction(data, mode);
  };

  const handleDelete = useCallback(async () => {
    if (!selectPromotion) return;

    const { id: promotion_id, code } = selectPromotion;

    try {
      await websiteFetcher(`${endpoint}${promotion_id}`, "DELETE");
      successNotifications(`${code} promotion deleted successfully`);
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    } finally {
      setSelectPromotion(null);
    }
  }, [selectPromotion]);

  const handleViewPromotion = (promotion) => {
    setSelectPromotion(promotion);
    setMode("view");
    open();
  };

  const handleEditPromotion = (promotion) => {
    setSelectPromotion(promotion);
    setMode("edit");
    open();
  };

  const handleDeletePromotion = (promotion) => {
    setSelectPromotion(promotion);
    openDelete();
  };

  const config = {
    fields: [
      { key: "code", label: "Code" },
      { key: "type", label: "Type" },
      {
        key: "effective_start_dt",
        label: "Effective start date",
        type: "datetime",
      },
      {
        key: "effective_end_dt",
        label: "Effective end date",
        type: "datetime",
      },
      { key: "amount", label: "Amount" },
      { key: "cover", label: "Cover Plan" },
      { key: "active", label: "Action" },
      { key: "created_on", label: "Created On", type: "datetime" },
      { key: "created_by", label: "Created by" },
      { key: "can_be_used", label: "can_be_used" },
    ],
    handler: [
      {
        label: "View",
        handler: (row) => handleViewPromotion(row),
      },
      {
        label: "Edit",
        handler: (row) => handleEditPromotion(row),
      },
      {
        label: "Delete",
        handler: (row) => handleDeletePromotion(row),
      },
    ],
  };

  return (
    <>
      <Flex
        mih={50}
        gap="md"
        justify="end"
        align="center"
        direction="row"
        wrap="wrap"
        mb="lg"
      >
        <Button
          variant="outline"
          onClick={() => {
            setSelectPromotion(null);
            setMode("create");
            open();
          }}
        >
          + Create Promotion Code
        </Button>
      </Flex>

      <DataWrapper
        endpoint={endpoint}
        config={config}
        useWebsiteFetcher={true}
      />

      <Modal
        opened={opened}
        onClose={() => {
          close();
        }}
        size="50rem"
        style={{ textTransform: "capitalize" }}
        centered
        title={"Transactions"}
        closeButtonProps={{
          icon: (
            <img
              src="/images/svg/close.svg"
              style={{ width: "1.6rem" }}
              alt="close-icon"
            />
          ),
        }}
      >
        <AddViewEditPromotionForm
          onSubmit={onSubmit}
          initialData={selectPromotion}
          mode={mode}
        />
      </Modal>

      <ConfirmModal
        opened={openedDelete}
        onClose={closeDelete}
        title="Confirm Delete"
        content={`Are you sure you want to delete this promotion ?`}
        onCancel={closeDelete}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default PromotionList;
