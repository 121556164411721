import { useSelector } from "react-redux";
import WalletCard from "../../../../common/components/WalletCard/WalletCard";
import useSWR from "swr";
import { USER_TYPES } from "../../../../utils/userTypes";
import { fetcher } from "../../../../services/fetcher";
// import { Button } from "@mantine/core";
import classes from "./WalletDetailSection.module.css";

const WalletDetailSection = () => {
  const { user_type } = useSelector((state) => state.auth);
  const operatorProfile = useSelector((state) => state.operatorProfile);

  const email = operatorProfile?.operator?.email || null;
  const walletEndpoint = `asc-wallet/user-wallet?email=${email}`;

  // Fetch wallet data
  const { data: walletData, isLoading: isWalletLoading, error: walletError } = useSWR(
    user_type.includes(USER_TYPES.OPERATOR) ? walletEndpoint : null,
    fetcher
  );

  return (
    <>
      {isWalletLoading ? (
        <p>Loading wallet data...</p>
      ) : walletError ? (
        <p>Error loading wallet data.</p>
      ) : (
        <div className={classes.flexContainer}>    
            
            <div className={classes.walletbox}>
              <WalletCard
                  balance={walletData?.balance}
                  type={walletData?.currency_type}
              />
            </div>
        </div>
      )}
    </>
  );
};

export default WalletDetailSection;