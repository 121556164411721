import { useState } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { Grid, Paper, Title, TextInput, Button } from "@mantine/core";
import dayjs from "dayjs";
import TripStatusChart from "../components/ColumnChart/ColumnChart";
import BarChart from "../components/BarChart/BarChart";
import TopOperatorsChart from "../components/BarChartOperator/BarChartOperator";
import GroupBarChart from "../components/GroupBarChart/GroupBarChart";
import DonutChart from "../components/DonutChart/DonutChart";
import VendorBarChart from "../components/VendorBarChart/VendorBarChart";
import TravellersChart from "../components/TravellersChart/TravellersChart";
import TopSellingPlansChart from "../components/TopSellingPlansChart/TopSellingPlansChart";
import { fetcher } from "../../../services/fetcher";
import { USER_TYPES } from "../../../utils/userTypes";
import WalletDetailSection from "../containers/WalletDetailSection/WalletDetailSection";
import PaymentDetails from "../containers/PaymentDetails/PaymentDetails";
import AssignPlan from "../containers/AssignPlan/AssignPlan";
import FilterMenu from "../components/FilterMenu/FilterMenu";
import Stats from "../containers/Stats/Stats";
import NationalityStats from "../containers/NationalityStat/NationalityStat";
import MainLoader from "../../../common/components/Loader/Loader";
import classes from "./Dashboard.module.css";

const Dashboard = () => {
  const { user_type } = useSelector((state) => state.auth);
  const [filters, setFilters] = useState({
    paymentGateway: { startDate: null, endDate: null, month: null, year: null },
    vendorPolicy: { startDate: null, endDate: null },
    travellersNationality: { month: null, year: null },
    topOperators: { month: null, year: null },
    travellerStatus: { month: null, year: null },
    monthlyTravellers: { year: null },
    topSellingCovers: { month: null, year: null },
  });
  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCover, setSelectedCover] = useState(null);

  // Handlers for updating filters for each chart
  const updateFilter = (chartKey, newFilter) => {
    setFilters((prev) => ({
      ...prev,
      [chartKey]: { ...prev[chartKey], ...newFilter },
    }));
  };
  // Endpoints
  const getEndpoint = (base, params = {}) => {
    const query = Object.entries(params)
      .filter(([, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    return query ? `${base}?${query}` : base;
  };
  const paymentGatewayEndpoint = getEndpoint(
    "dashboard/gateway-totals-amount/",
    {
      filter_type:
        filters.paymentGateway.startDate && filters.paymentGateway.endDate
          ? "custom"
          : filters.paymentGateway.month
          ? "month"
          : "year",
      start_date: filters.paymentGateway.startDate
        ? dayjs(filters.paymentGateway.startDate).format("YYYY-MM-DD")
        : undefined,
      end_date: filters.paymentGateway.endDate
        ? dayjs(filters.paymentGateway.endDate).format("YYYY-MM-DD")
        : undefined,
      month: filters.paymentGateway.month,
      year: filters.paymentGateway.year,
      cover_id: selectedCover?.id,
    }
  );
  const vendorWisePolicyEndpoint = getEndpoint(
    "dashboard/vendor-wise-policy/",
    {
      start_date: filters.vendorPolicy.startDate
        ? dayjs(filters.vendorPolicy.startDate).format("YYYY-MM-DD")
        : undefined,
      end_date: filters.vendorPolicy.endDate
        ? dayjs(filters.vendorPolicy.endDate).format("YYYY-MM-DD")
        : undefined,
    }
  );
  const travellersByNationalityEndpoint = getEndpoint(
    "dashboard/travellers-by-nationality/",
    {
      month: filters.travellersNationality.month,
      year: filters.travellersNationality.year,
    }
  );
  const topOperatorEndpoint = getEndpoint("dashboard/top-operators/", {
    month: filters.topOperators.month,
    year: filters.topOperators.year,
  });
  const travellerAgeGroupEndpoint = getEndpoint(
    "dashboard/traveller-age-groups/",
    {
      start_date: filters.travellerStatus.startDate
        ? dayjs(filters.travellerStatus.startDate).format("YYYY-MM-DD")
        : undefined,
      end_date: filters.travellerStatus.endDate
        ? dayjs(filters.travellerStatus.endDate).format("YYYY-MM-DD")
        : undefined,
      month: filters.travellerStatus.month,
      year: filters.travellerStatus.year || null,
      cover_id: selectedCover?.id,
    }
  );
  const travellerPolicyCountEndpoint = getEndpoint(
    "dashboard/policy-count-month-wise/",
    {
      year: filters.monthlyTravellers.year,
      cover_id: selectedCover?.id,
    }
  );
  const topSellingCoversEndpoint = getEndpoint("dashboard/top-selling-covers/", {
    filter_type: "custom",
    start_date: filters.topSellingCovers.startDate
      ? dayjs(filters.topSellingCovers.startDate).format("YYYY-MM-DD")
      : undefined,
    end_date: filters.topSellingCovers.endDate
      ? dayjs(filters.topSellingCovers.endDate).format("YYYY-MM-DD")
      : undefined,
  });

  const coverSearchEndpoint =
    searchInput && (!selectedCover || searchInput !== selectedCover.title)
      ? `dashboard/covers/search/?title=${searchInput}`
      : null;

  const { data: topSellingCoversData, isLoading: isTopSellingCoversLoading } =
    useSWR(
      user_type[0] === USER_TYPES.SUPERUSER ? topSellingCoversEndpoint : null,
      fetcher
  );
  const { data: paymentGatewayData, isLoading: isPaymentGatewayLoading } =
    useSWR(
      user_type[0] === USER_TYPES.SUPERUSER ? paymentGatewayEndpoint : null,
      fetcher
    );
  const { data: vendorWiseData, isLoading: isVendorWiseLoading } = useSWR(
    user_type[0] === USER_TYPES.SUPERUSER ? vendorWisePolicyEndpoint : null,
    fetcher
  );
  const {
    data: travellersByNationalityStats,
    isLoading: travellersByNationalityLoading,
  } = useSWR(
    user_type[0] === USER_TYPES.SUPERUSER
      ? travellersByNationalityEndpoint
      : null,
    fetcher
  );
  const { data: topOperatorsData, isLoading: isTopOperatorsLoading } = useSWR(
    user_type[0] === USER_TYPES.SUPERUSER ? topOperatorEndpoint : null,
    fetcher
  );
  const { data: travellerAgeGroupData, isLoading: isTravellerAgeGroupLoading } = useSWR(
    user_type[0] === USER_TYPES.SUPERUSER ? travellerAgeGroupEndpoint : null,
    fetcher
  );
  const {
    data: travellerPolicyCountData,
    isLoading: isTravellerPolicyCountLoading,
  } = useSWR(
    user_type[0] === USER_TYPES.SUPERUSER
      ? travellerPolicyCountEndpoint
      : null,
    fetcher
  );
  const { data: coverSearchResults } = useSWR(coverSearchEndpoint, fetcher);

  // Handle cover selection from search
  const handleCoverSelect = (cover) => {
    setSelectedCover(cover);
    setSearchInput(cover.title);
  };

  const handleClearSearch = () => {
    setSearchInput("");
    setSelectedCover(null);
  };

  return (
    <>
      <div className={classes.globalSearchContainer}>
       <Grid>
          <Grid.Col span={6}>
            <TextInput
              placeholder="Search Plans"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {coverSearchResults?.length > 0 && (
              <div className={classes.searchResults}>
                {coverSearchResults.map((cover) => (
                  <div
                    key={cover.id}
                    className={classes.searchResultItem}
                    onClick={() => handleCoverSelect(cover)}
                  >
                    {cover.title}
                  </div>
                ))}
              </div>
            )}
          </Grid.Col>
          <Grid.Col span={6}>
          {/* Add a clear button for the search */}
          <Button variant="gradient" onClick={handleClearSearch}  style={{ marginLeft: "1rem" }}>
            Clear Search
          </Button>
        </Grid.Col>
       </Grid>
      </div>
  
      {/* Conditional rendering for globalCoverSearch */}
      {selectedCover ? (
        <>
          {/* Payment Gateways */}
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Paper p="lg" style={{height:"100%"}}>
                <div className={classes.head}>
                  <Title className={classes.chartHeading}>
                    Payment Gateways Data
                  </Title>
                  <FilterMenu
                    startDate={filters.paymentGateway.startDate}
                    endDate={filters.paymentGateway.endDate}
                    setStartDate={(date) =>
                      updateFilter("paymentGateway", { startDate: date })
                    }
                    setEndDate={(date) =>
                      updateFilter("paymentGateway", { endDate: date })
                    }
                    setSelectedMonth={(month) =>
                      updateFilter("paymentGateway", { month })
                    }
                    setSelectedYear={(year) =>
                      updateFilter("paymentGateway", { year })
                    }
                  />
                </div>
                {isPaymentGatewayLoading ? (
                  <div><MainLoader /></div>
                ) : (
                  <DonutChart data={paymentGatewayData?.gateway_totals || []} 
                    combinedTotal={paymentGatewayData?.combined_total_amount || 0}
                  />
                )}
              </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Paper p="sm">
                <div className={classes.head}>
                  <Title className={classes.chartHeading}>Traveller Status</Title>
                  <FilterMenu
                    startDate={filters.travellerStatus.startDate}
                    endDate={filters.travellerStatus.endDate}
                    setStartDate={(date) =>
                      updateFilter("travellerStatus", { startDate: date })
                    }
                    setEndDate={(date) =>
                      updateFilter("travellerStatus", { endDate: date })
                    }
                    setSelectedMonth={(month) =>
                      updateFilter("travellerStatus", { month })
                    }
                    setSelectedYear={(year) =>
                      updateFilter("travellerStatus", { year })
                    }
                  />
                </div>
                {isTravellerAgeGroupLoading ? (
                  <div><MainLoader /></div>
                ) : (
                  <GroupBarChart data={travellerAgeGroupData} />
                )}
              </Paper>
            </Grid.Col>
          </Grid>
  
          {/* Monthly Travellers */}
          <Grid mt="lg">
            <Grid.Col span={12}>
              <Paper p="lg">
                <Title className={classes.chartHeading}>Monthly Travellers</Title>
                {isTravellerPolicyCountLoading ? (
                  <MainLoader />
                ) : (
                  <TravellersChart
                    data={travellerPolicyCountData || []}
                    title="Travellers Data"
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                )}
              </Paper>
            </Grid.Col>
          </Grid>
        </>
      ) : (
        /* Default rendering when globalCoverSearch is empty */
        <>
          {(user_type[0] === USER_TYPES.SUPERUSER || user_type[0] === USER_TYPES.OPERATOR) && (
            <>
              {user_type[0] === USER_TYPES.OPERATOR && (
                <div className={classes.walletContainer}>
                  <WalletDetailSection />
                </div>
              )}
              <TripStatusChart />
              {user_type[0] === USER_TYPES.OPERATOR && (
                <Grid mt="lg" style={{ display: "flex", height: "100%", minHeight: "300px", gap: "16px"}}>
                  <Grid.Col span={{ base: 12, md: 6, lg: 6 }} style={{display: "flex", flexDirection: "column", flexGrow: 1, height: "100%"}}>
                    <Paper style={{ flex: 1, padding: "24px"}} shadow="md">
                      <AssignPlan />
                    </Paper>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6, lg: 6 }} style={{display: "flex", flexDirection: "column", flexGrow: 1, height: "100%"}}>
                    <Paper style={{flex: 1, padding: "24px"}} shadow="md">
                      <PaymentDetails />
                    </Paper>
                  </Grid.Col>
                </Grid>
              )}
            </>
          )}
          {user_type[0] === USER_TYPES.SUPERUSER && (
            <>
          {/* Gateway and Vendor */}
          <Grid mt="lg">
            <Grid.Col span={{ base: 12, md: 7, lg: 7 }}>
              <Paper p="xl" style={{height: "100%"}}>
                <div className={classes.head}>
                  <Title className={classes.chartHeading}>
                    Payment Gateways Data
                  </Title>
                  <FilterMenu
                    startDate={filters.paymentGateway.startDate}
                    endDate={filters.paymentGateway.endDate}
                    setStartDate={(date) =>
                      updateFilter("paymentGateway", { startDate: date })
                    }
                    setEndDate={(date) =>
                      updateFilter("paymentGateway", { endDate: date })
                    }
                    setSelectedMonth={(month) =>
                      updateFilter("paymentGateway", { month })
                    }
                    setSelectedYear={(year) =>
                      updateFilter("paymentGateway", { year })
                    }
                  />
                </div>
                {isPaymentGatewayLoading ? (
                  <div><MainLoader /></div>
                ) : (
                  <DonutChart data={paymentGatewayData?.gateway_totals || []} 
                    combinedTotal={paymentGatewayData?.combined_total_amount || 0}
                  />
                )}
              </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 5, lg: 5 }}>
              <Paper p="xl">
                <div className={classes.head}>
                  <Title className={classes.chartHeading}>
                    Vendors Status
                  </Title>
                  <FilterMenu
                    startDate={filters.vendorPolicy.startDate}
                    endDate={filters.vendorPolicy.endDate}
                    setStartDate={(date) =>
                      updateFilter("vendorPolicy", { startDate: date })
                    }
                    setEndDate={(date) =>
                      updateFilter("vendorPolicy", { endDate: date })
                    }
                    hideMonthYear
                  />
                </div>
                {isVendorWiseLoading ? (
                  <div><MainLoader /></div>
                ) : (
                  <VendorBarChart data={vendorWiseData || {}} />
                )}
              </Paper>
            </Grid.Col>
          </Grid>
          {/* Traveller Status and Monthly Data */}
          <Grid mt="lg">
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Paper p="xl">
                <div className={classes.head}>
                  <Title className={classes.chartHeading}>Traveller Status</Title>
                  <FilterMenu
                    startDate={filters.travellerStatus.startDate}
                    endDate={filters.travellerStatus.endDate}
                    setStartDate={(date) =>
                      updateFilter("travellerStatus", { startDate: date })
                    }
                    setEndDate={(date) =>
                      updateFilter("travellerStatus", { endDate: date })
                    }
                    setSelectedMonth={(month) =>
                      updateFilter("travellerStatus", { month })
                    }
                    setSelectedYear={(year) =>
                      updateFilter("travellerStatus", { year })
                    }
                  />
                </div>
                {isTravellerAgeGroupLoading ? (
                  <div><MainLoader /></div>
                ) : (
                  <GroupBarChart data={travellerAgeGroupData} />
                )}
              </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Stats data={travellerAgeGroupData} />
            </Grid.Col>    
          </Grid>        
          {/* Nationality and Operators */}
          <Grid mt="lg">
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Paper p="xl">
              <div className={classes.head}>
                <Title className={classes.chartHeading}>
                  Travellers by Nationality
                </Title>
                <FilterMenu
                  setSelectedMonth={(month) =>
                    updateFilter("travellersNationality", { month })
                  }
                  setSelectedYear={(year) =>
                    updateFilter("travellersNationality", { year })
                  }
                  hideDateFilters
                />
              </div>
              {travellersByNationalityLoading ? (
                <div><MainLoader /></div>
              ) : (
                <BarChart data={travellersByNationalityStats} />
              )}
            </Paper>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <NationalityStats data={travellersByNationalityStats} />
          </Grid.Col>
          </Grid>
          <Grid mt="lg">
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Paper p="xl">
                <div className={classes.head}>
                  <Title className={classes.chartHeading}>Top Operators</Title>
                  <FilterMenu
                    setSelectedMonth={(month) =>
                      updateFilter("topOperators", { month })
                    }
                    setSelectedYear={(year) =>
                      updateFilter("topOperators", { year })
                    }
                  />
                </div>
                <TopOperatorsChart
                  data={topOperatorsData}
                  isLoading={isTopOperatorsLoading}
                />
              </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Paper p="xl">
                <div className={classes.head}>
                  <Title className={classes.chartHeading}>Top Selling Covers</Title>
                  <FilterMenu
                    startDate={filters.topSellingCovers.startDate}
                    endDate={filters.topSellingCovers.endDate}
                    setStartDate={(date) =>
                      updateFilter("topSellingCovers", { startDate: date })
                    }
                    setEndDate={(date) =>
                      updateFilter("topSellingCovers", { endDate: date })
                    }
                    hideMonthYear
                  />
                </div>
                <TopSellingPlansChart
                  endpoint={topSellingCoversEndpoint} 
                  data={topSellingCoversData}
                  isLoading={isTopSellingCoversLoading}
                />
              </Paper>
            </Grid.Col>
          </Grid>
          <Grid mt="lg">
            <Grid.Col span={12}>
              <Paper p="xl">
                <div className={classes.head}>
                  <Title className={classes.chartHeading}>
                    Monthly Travellers
                  </Title>
                </div>
                {isTravellerPolicyCountLoading ? (
                  <div><MainLoader /></div>
                ) : (
                  <TravellersChart
                    data={travellerPolicyCountData}
                  />
                )}
              </Paper>
            </Grid.Col>
          </Grid>
        </>
         )}
        </>
      )}
    </>
  );
  
};
export default Dashboard;