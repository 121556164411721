// import { Grid, Card, Text, Title } from "@mantine/core";
// import classes from "./NationalityStats.module.css";

// const NationalityStats = ({ data }) => {
//   const nationalityIcons = {
//     Indian: "/images/svg/active-policy.svg",
//     Malaysian: "/images/svg/inactive-policy.svg",
//     American: "/images/svg/matured-policy.svg",
//     British: "/images/svg/pending-policy.svg",
//     Thai: "/images/svg/active-policy.svg",
//   };

//   const colors = {
//     Indian: "#4caf50",
//     Malaysian: "#f44336",
//     American: "#2196f3",
//     British: "#ff9800",
//     Thai: "#ff9800",
//   };

//   if (!data || data.length === 0) return <div>No data available</div>;

//   return (
//     <Grid>
//       {data.map((item) => (
//         <Grid.Col span={6} key={item.nationality}>
//           <Card
//             className={classes.box}
//             shadow="md"
//             padding="lg"
//             h={126}
//           >
//             <div
//               className={classes.verticalLine}
//               style={{ backgroundColor: colors[item.nationality] }}
//             />
//             <div className={classes.cardContent}>
//               <div className={classes.cardHeader}>
//                 <Title className={classes.stats}>{item.nationality}</Title>
//                 <img
//                   src={nationalityIcons[item.nationality]}
//                   alt={`${item.nationality} icon`}
//                   className={classes.icon}
//                 />
//               </div>
//               <Text className={classes.count}>{item.traveller_count}</Text>
//             </div>
//           </Card>
//         </Grid.Col>
//       ))}
//     </Grid>
//   );
// };

// export default NationalityStats;

import { Grid, Card, Text, Title } from "@mantine/core";
import classes from "./NationalityStats.module.css";

const NationalityStats = ({ data }) => {
  const nationalityIcons = {
    Indian: "/images/svg/active-policy.svg",
    Malaysian: "/images/svg/inactive-policy.svg",
    American: "/images/svg/matured-policy.svg",
    British: "/images/svg/pending-policy.svg",
    Thai: "/images/svg/active-policy.svg",
  };

  const colors = {
    Indian: "#4caf50",
    Malaysian: "#f44336",
    American: "#2196f3",
    British: "#ff9800",
    Thai: "#ff9800",
  };

  const defaultData = Object.keys(nationalityIcons).map((nationality) => ({
    nationality,
    traveller_count: 0,
  }));

  const mergedData = defaultData.map((defaultItem) => {
    const matchingData = data?.find((item) => item.nationality === defaultItem.nationality);
    return matchingData || defaultItem;
  });

  return (
    <Grid>
      {mergedData.map((item) => (
        <Grid.Col span={6} key={item.nationality}>
          <Card className={classes.box} shadow="md" padding="lg" h={127}>
            <div
              className={classes.verticalLine}
              style={{ backgroundColor: colors[item.nationality] }}
            />
            <div className={classes.cardContent}>
              <div className={classes.cardHeader}>
                <Title className={classes.stats}>{item.nationality}</Title>
                <img
                  src={nationalityIcons[item.nationality]}
                  alt={`${item.nationality} icon`}
                  className={classes.icon}
                />
              </div>
              <Text className={classes.count}>{item.traveller_count}</Text>
            </div>
          </Card>
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default NationalityStats;
