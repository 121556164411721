import { useState } from "react";
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  TextInput,
  rem,
  Paper,
  Button,
  Checkbox,
  Divider,
  Flex,
  Select,
  NumberInput,
} from "@mantine/core";
import { Controller } from "react-hook-form";

import iconSearch from "../../../assets/svg/search.svg";
import MainLoader from "../Loader/Loader";
import { USER_TYPES } from "../../../utils/userTypes";

import withFormattedOptions from "../../hoc/withFormattedOptions/withFormattedOptions";

import classes from "./FormDataTable.module.css";

const EnhancedSelect = withFormattedOptions(Select);

function Th({ children, ...props }) {
  return (
    <Table.Th className={classes.th} {...props}>
      <UnstyledButton className={classes.control}>
        <Group justify="space-between" wrap="nowrap">
          <Text fw={500} fz="sm" component="span">
            {children}
          </Text>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const FormDataTable = ({
  data,
  config,
  heading,
  loading,
  setSelection,
  selection,
  handleSelection,
  onSubmitRow,
  control,
  userType,
}) => {
  const [search, setSearch] = useState("");

  const selectRow = (id) =>
    setSelection((current) =>
      current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id]
    );

  const selectAllRow = () =>
    setSelection((current) =>
      current.length === data.length ? [] : data.map((item) => item.id)
    );

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };

  const rows = data.map((row, index) => (
    <Table.Tr key={index}>
      {config.selectionCheckbox && (
        <Table.Td>
          <Checkbox
            radius="sm"
            checked={selection.includes(row.id)}
            onChange={() => selectRow(row.id)}
          />
        </Table.Td>
      )}

      {config.fields.map((field) => {
        return (
          <Table.Td key={`${row.id}-${field.key}`}>
            {field.isInput && (
              <Controller
                name={`${field.key}_${row.id}`}
                control={control}
                defaultValue={getNestedValue(row, field.key)}
                render={({ field: controllerField }) => (
                  <NumberInput
                    data={field.options}
                    value={controllerField.value}
                    onChange={controllerField.onChange}
                    readOnly={userType !== USER_TYPES.SUPERUSER}
                    decimalScale={2}
                    allowNegative={false}
                    hideControls
                  />
                )}
              />
            )}

            {field.isEnhanceSelect && (
              <Controller
                name={`${field.key}_${row.id}`}
                control={control}
                defaultValue={getNestedValue(row, field.key)?.toString()}
                render={({ field: controllerField }) => (
                  <EnhancedSelect
                    data={field.options}
                    getOptionValue={field.getOptionValue}
                    getOptionLabel={field.getOptionLabel}
                    value={controllerField.value}
                    onChange={(value) => {
                      controllerField.onChange(value);
                    }}
                    readOnly={userType !== USER_TYPES.SUPERUSER}
                  />
                )}
              />
            )}

            {field.isSelect && (
              <Controller
                name={`${field.key}_${row.id}`}
                control={control}
                defaultValue={getNestedValue(row, field.key)?.toString()}
                render={({ field: controllerField }) => (
                  <Select
                    data={field.options}
                    value={controllerField.value}
                    onChange={controllerField.onChange}
                    readOnly={userType !== USER_TYPES.SUPERUSER}
                  />
                )}
              />
            )}

            {!field.isInput && !field.isSelect && !field.isEnhanceSelect && (
              <Text
                size="sm"
                my={"9px"}
                fw={field.key === config.fields[0].key && 600}
                styles={() => ({
                  root: {
                    color:
                      field.key === config.fields[0].key ? "inherit" : "#777",
                  },
                })}
              >
                {getNestedValue(row, field.key)}
              </Text>
            )}
          </Table.Td>
        );
      })}

      {config.actions && (
        <Table.Td>
          {config.actions.map((action, index) => (
            <Button
              key={index}
              variant="light"
              onClick={() => {
                if (typeof action.to === "function") {
                  action.to(row);
                }
              }}
            >
              {action.icon && <img src={action.icon} />}
              {`${action.label}`}
            </Button>
          ))}
        </Table.Td>
      )}

      {control && userType === USER_TYPES.SUPERUSER && (
        <Table.Td
          style={{
            position: "sticky",
            right: 0,
            backgroundColor: "var(--mantine-color-body)",
            zIndex: 1,
          }}
        >
          <Button variant="outline" onClick={() => onSubmitRow(row)}>
            Update
          </Button>
        </Table.Td>
      )}
    </Table.Tr>
  ));

  const headerStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "var(--mantine-color-body)",
    transition: "box-shadow 150ms ease",
  };

  return (
    <Paper>
      <Group justify="space-between">
        <Group className={classes.header} justify="flex-start">
          <Text className={classes.heading}>{heading}</Text>
        </Group>
        <Group className={classes.header} justify="flex-end">
          <TextInput
            placeholder="Search by any field"
            mb="md"
            leftSection={
              <img
                style={{ width: rem(16), height: rem(16) }}
                src={iconSearch}
              />
            }
            value={search}
            onChange={handleSearchChange}
          />
        </Group>
      </Group>

      <ScrollArea h={config.scrollable ? 320 : ""}>
        <Table
          horizontalSpacing="sm"
          verticalSpacing="xs"
          miw={700}
          layout="fixed"
          withRowBorders={false}
          highlightOnHover
          style={{ minWidth: "100%", tableLayout: "auto" }}
        >
          <Table.Thead style={headerStyle}>
            <Table.Tr>
              {config.selectionCheckbox && (
                <Table.Th style={{ width: rem(40) }}>
                  <Checkbox
                    onChange={selectAllRow}
                    radius={"sm"}
                    checked={selection.length === data.length}
                    indeterminate={
                      selection.length > 0 && selection.length !== data.length
                    }
                  />
                </Table.Th>
              )}

              {config.fields.map((field, index) => (
                <Th
                  key={`${field.label}${index}`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Text fw={600} component="span">
                    {field.label}
                  </Text>
                </Th>
              ))}

              {control && userType === USER_TYPES.SUPERUSER && (
                <Th
                  style={{
                    position: "sticky",
                    right: 0,
                    backgroundColor: "var(--mantine-color-body)",
                    zIndex: 2,
                  }}
                >
                  <Text fw={600}>Actions</Text>
                </Th>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {loading ? (
              <Table.Tr>
                <Table.Td
                  colSpan={
                    config.fields.length +
                    (config.actions ? 1 : 0) +
                    (config.selectionCheckbox ? 1 : 0)
                  }
                >
                  <MainLoader />
                </Table.Td>
              </Table.Tr>
            ) : rows.length > 0 ? (
              rows
            ) : (
              <Table.Tr>
                <Table.Td
                  colSpan={
                    config.fields.length +
                    (config.actions ? 1 : 0) +
                    (config.selectionCheckbox ? 1 : 0)
                  }
                >
                  <Text fw={500} ta="center">
                    Nothing found
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </ScrollArea>
      {config.selectionCheckbox && (
        <>
          <Divider mb={"lg"} />
          <Flex
            mih={50}
            gap="md"
            justify="center"
            align="center"
            direction="row"
            wrap="wrap"
            mb={"lg"}
          >
            <Button variant="outline" mb={"lg"} onClick={handleSelection}>
              Save
            </Button>
          </Flex>
        </>
      )}
    </Paper>
  );
};

export default FormDataTable;
