import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Select,
  SimpleGrid,
  NumberInput,
  Group,
  Switch,
  Button,
  Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

import { AsyncAutocomplete } from "../../../../../../common/components/AsyncAutocomplete/AsyncAutocomplete";

const AddViewEditPromotionForm = ({
  onSubmit,
  initialData,
  mode = "create",
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialData || {},
  });

  useEffect(() => {
    if (initialData) {
      if (mode === "edit") {
        reset({
          ...initialData,
          effective_start_dt: new Date(initialData.effective_start_dt),
          effective_end_dt: new Date(initialData.effective_end_dt),
        });
      } else {
        reset(initialData);
      }
    }
  }, [initialData, mode, reset]);

  const renderField = (label, value) => (
    <div>
      <Text fw={500} size="sm" mb={4}>
        {label}
      </Text>
      <Text>{value || "N/A"}</Text>
    </div>
  );

  if (mode === "view") {
    return (
      <SimpleGrid cols={2} spacing="lg" verticalSpacing="lg">
        {renderField("Code", initialData?.code)}
        {renderField("Type", initialData?.type)}
        {renderField("Amount", initialData?.amount)}
        {renderField("Can be used", initialData?.can_be_used)}
        {renderField("Cover", initialData?.cover)}

        {renderField("Effective Start Date", initialData?.effective_start_dt)}
        {renderField("Effective End Date", initialData?.effective_end_dt)}

        {renderField("Created On", initialData?.created_on)}
        {renderField("Created By", initialData?.created_by)}

        {renderField("Active", initialData?.active ? "Yes" : "No")}
      </SimpleGrid>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid cols={2}>
        {mode === "create" && (
          <AsyncAutocomplete
            endpoint={"dashboard/covers/search/"}
            queryKey="title"
            control={control}
            name={"cover"}
            getOptionValue={(item) => item.id.toString()}
            getOptionLabel={(item) => item.title}
          />
        )}

        <Controller
          name="amount"
          control={control}
          rules={{ required: "Amount is required" }}
          render={({ field }) => (
            <NumberInput
              label="Amount"
              placeholder="Enter amount"
              decimalScale={2}
              allowNegative={false}
              hideControls
              error={errors.amount?.message}
              {...field}
            />
          )}
        />

        <Controller
          name="type"
          control={control}
          rules={{ required: "Promotion Type is required" }}
          render={({ field }) => (
            <Select
              label="Promotion Type"
              placeholder="Select promotion type"
              data={[
                { label: "Flat Discount", value: "Flat Discount" },
                { label: "Percentage", value: "Percentage" },
              ]}
              error={errors.type?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          name="can_be_used"
          control={control}
          rules={{ required: "Can be used is required" }}
          render={({ field }) => (
            <NumberInput
              label="Can Be Used"
              placeholder="Enter no. of can be used"
              allowDecimal={false}
              allowNegative={false}
              max={1000}
              min={1}
              hideControls
              error={errors.can_be_used?.message}
              {...field}
            />
          )}
        />

        <Controller
          name="effective_start_dt"
          control={control}
          rules={{ required: "Effective Start date is required" }}
          render={({ field, fieldState: { error } }) => (
            <DatePickerInput
              label="Effective Start Date"
              placeholder="enter effective start date"
              valueFormat="YYYY MMM DD"
              // value={field.value || null}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={error?.message}
            />
          )}
        />

        <Controller
          name="effective_end_dt"
          control={control}
          rules={{ required: "Effective End date is required" }}
          render={({ field, fieldState: { error } }) => (
            <DatePickerInput
              label="Effective End Date"
              placeholder="enter effective end date"
              valueFormat="YYYY MMM DD"
              // value={field.value || null}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={error?.message}
            />
          )}
        />

        <Controller
          name="active"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              checked={field.value}
              label="Active"
              style={{
                marginTop: "36px",
                fontWeight: 600,
                color: "#333",
                fontSize: "0.9rem",
              }}
              onChange={(event) => {
                const value = event.currentTarget.checked;
                field.onChange(value);
              }}
            />
          )}
        />
      </SimpleGrid>

      <Group justify="center">
        <Button variant="gradient" type="submit" mt="xl" size="md">
          {"Submit"}
        </Button>
      </Group>
    </form>
  );
};

export default AddViewEditPromotionForm;
