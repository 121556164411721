import { useState, useCallback, useTransition, useMemo } from "react";
import { FloatingIndicator, Tabs, Skeleton } from "@mantine/core";
import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";

import DataWrapper from "../../../../common/components/DataWrapper/DataWrapper";
import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";
import {
  filterOptions as baseFilterOptions,
  sortOptions,
} from "../../utils/trips";
import classes from "./Trips.module.css";

const tabConfig = [
  { value: "1", label: "All", endpoint: "issuance/policies/all/trips/" },
  {
    value: "2",
    label: "Website",
    endpoint: "issuance/policies/website/trips/",
  },
  {
    value: "3",
    label: "Operator",
    endpoint: "issuance/policies/operator/trips/",
  },
  { value: "4", label: "Issued", endpoint: "issuance/policies/issued/trips/" },
  {
    value: "5",
    label: "Unissued",
    endpoint: "issuance/policies/unissued/trips/",
  },
];

const operators = "operator/operator-profiles/";

const Trips = () => {
  const [rootRef, setRootRef] = useState(null);
  const [value, setValue] = useState("1");
  const [controlsRefs, setControlsRefs] = useState({});
  const [currentTab, setCurrentTab] = useState("1");
  const [isPending, startTransition] = useTransition();

  const { data: operatorData, isLoading: isOperatorsLoading } = useSWR(
    operators,
    fetcher
  );

  const setControlRef = (val) => (node) => {
    controlsRefs[val] = node;
    setControlsRefs(controlsRefs);
  };

  const handleTabChange = (newValue) => {
    startTransition(() => {
      setValue(newValue);
      setCurrentTab(newValue);
    });
  };

  const handleTripDetails = (row) => {
    return `trips/${row.id}`;
  };

  const operatorOptions = useMemo(() => {
    if (!operatorData) return;

    if (operatorData) {
      return operatorData.map((operator) => ({
        name: operator.operator_name,
        id: operator.operator.id,
      }));
    }
  }, [operatorData]);

  const handleCoversExport = useCallback(async (row) => {
    const { id: trip_id } = row;

    try {
      const response = await fetcher(
        `issuance/download-cover-documents/?trip_id=${trip_id}`,
        "POST",
        null,
        {},
        { responseType: "arraybuffer" }
      );

      if (response) {
        const zipBlob = new Blob([response], {
          type: "application/zip",
        });

        const url = window.URL.createObjectURL(zipBlob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `ASCTrip${trip_id}.zip`;

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        successNotifications("Covers of trips exported successfully");
      } else {
        throw new Error("Export failed");
      }
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  }, []);

  const handleTripsExport = useCallback(async (row) => {
    const { id: trip_id } = row;

    const payload = { trip_id: trip_id };

    try {
      const response = await fetcher(
        "issuance/export/selected-travellers/",
        "POST",
        payload
      );

      if (response) {
        const csvBlob = new Blob([response], { type: "text/csv" });

        const url = window.URL.createObjectURL(csvBlob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = "exported_trips.csv";

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        successNotifications("Trips exported successfully");
      } else {
        throw new Error("Export failed");
      }
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  }, []);

  const tripConfig = {
    fields: [
      { key: "id", label: "Booking ID" },
      { key: "cover_type", label: "Cover Type" },
      { key: "cover_name", label: "Cover Name" },
      { key: "traveller_from_name", label: "Travel From" },
      { key: "traveller_to_name", label: "Travel To" },
      { key: "no_of_pax", label: "Pax" },
      { key: "tour_operator_name", label: "Tour Operator" },
      {
        key1: "currency",
        key2: "total_amount",
        label: "Total Amount",
        type: "combine",
      },
      { key: "coverage_start_date", label: "Start Date", type: "date" },
      { key: "coverage_end_date", label: "End Date", type: "date" },
      { key: "created_on", label: "Booking Date", type: "datetime" },
      { key: "created_by_name", label: "Issued by" },
      { key: "status", label: "Status", type: "status" },
    ],
    actions: [
      {
        label: "Details",
        to: (row) => handleTripDetails(row),
        isButton: true,
      },
    ],
    handler: [
      {
        label: "Export",
        handler: (row) => handleTripsExport(row),
      },
      {
        label: "Export Cover",
        handler: (row) => handleCoversExport(row),
      },
    ],
  };

  const filterOptions = useMemo(
    () => [
      ...baseFilterOptions,
      {
        label: "Operator",
        value: "operator_id",
        type: "select",
        options: operatorOptions,
        roles: ["is_superuser"],
      },
    ],
    [operatorOptions]
  );

  return (
    <Tabs variant="none" value={value} onChange={handleTabChange}>
      <Tabs.List ref={setRootRef} className={classes.list}>
        {tabConfig.map((tab) => (
          <Tabs.Tab
            key={tab.value}
            value={tab.value}
            ref={setControlRef(tab.value)}
            className={classes.tab}
          >
            {tab.label}
          </Tabs.Tab>
        ))}

        <FloatingIndicator
          target={value ? controlsRefs[value] : null}
          parent={rootRef}
          className={classes.indicator}
        />
      </Tabs.List>

      {isPending ? (
        <Skeleton visible={isPending} height={600}></Skeleton>
      ) : (
        tabConfig.map((tab) =>
          tab.value === currentTab ? (
            <Tabs.Panel key={tab.value} value={tab.value}>
              <DataWrapper
                filterOptions={isOperatorsLoading ? [] : filterOptions}
                endpoint={tab.endpoint}
                config={tripConfig}
                sortOptions={sortOptions}
              />
            </Tabs.Panel>
          ) : null
        )
      )}
    </Tabs>
  );
};

export default Trips;
