import Chart from "react-apexcharts";
import { Loader } from "@mantine/core";

const GroupBarChart = ({ data, isLoading }) => {
  if (isLoading) {
    return <Loader />;
  }

  const categories = Object.keys(data || {}); // Age groups
  const maleData = categories.map((group) => data[group].male);
  const femaleData = categories.map((group) => data[group].female);

  const options = {
    chart: {
      type: "bar",
    },
    colors: ["#4F52F9", "#DE7BFF"],
    xaxis: {
      categories: categories,
      // title: {
      //   text: "Age Groups",
      // },
    },
    yaxis: {
      // title: {
      //   text: "Number of Travellers",
      // },
    },
    grid: {
      show: true, 
      strokeDashArray: 4, 
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        barHeight: "100%",
        endingShape: "rounded",
        distributed: false, 
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: "bottom",
    },
  };

  const series = [
    {
      name: "Male",
      data: maleData,
    },
    {
      name: "Female",
      data: femaleData,
    },
  ];

  return <Chart options={options} series={series} type="bar" height={250} />;
};

export default GroupBarChart;
