import { Grid } from "@mantine/core";
import CountStatsControls from "../../components/CountStatsControls/CountStatsControls";

const Stats = ({ data }) => {
  if (!data) return <div>No data available</div>;

  const ageGroupIcons = {
    "70+": "/images/svg/active-policy.svg",
    "60-69": "/images/svg/inactive-policy.svg",
    "50-59": "/images/svg/matured-policy.svg",
    "1-49": "/images/svg/pending-policy.svg",
  };

  const ageGroupColors = {
    "70+": "#4caf50",
    "60-69": "#f44336",
    "50-59": "#2196f3",
    "1-49": "#ff9800",
  };

  return (
    <Grid>
      {Object.keys(ageGroupIcons).map((ageGroup) => {
        const iconPath = ageGroupIcons[ageGroup];
        const lineColor = ageGroupColors[ageGroup];
        const ageGroupData = data[ageGroup] || { male: 0, female: 0 };
        const { male, female } = ageGroupData;

        return (
          <Grid.Col span={6} key={ageGroup}>
            <CountStatsControls
              title={`${ageGroup} Age Group`}
              count={`${male + female}`}
              icon={<img src={iconPath} alt={`${ageGroup} icon`} style={{ height: "20px", width: "20px" }} />}
              verticalLineColor={lineColor}
            />
          </Grid.Col>
        );
      })}
    </Grid>
  );
};

export default Stats;
