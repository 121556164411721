const withFormattedOptions = (WrappedComponent) => {
  return function EnhancedSelect({
    data,
    getOptionValue = null,
    getOptionLabel = null,
    ...props
  }) {
    const formatData = (data) => {
      if (!Array.isArray(data)) {
        return [];
      }

      if (getOptionValue && getOptionLabel) {
        return data.map((item) => ({
          value: getOptionValue(item),
          label: getOptionLabel(item),
        }));
      }

      if (getOptionValue && !getOptionLabel) {
        return data.map((item) => ({
          value: getOptionValue(item),
        }));
      }

      if (!getOptionValue && !getOptionLabel) {
        throw new Error("getOptionValue must be provided");
      }

      return [];
    };

    const formattedOptions = formatData(data);

    return <WrappedComponent data={formattedOptions} {...props} />;
  };
};

export default withFormattedOptions;
