const paymentTypes = [
  { label: "Policy Purchase", value: "policy" },
  { label: "Wallet Recharge", value: "wallet_recharge" },
  { label: "Link Policy", value: "link_policy" },
];

const transactionStatus = [
  { label: "Pending", value: "pending" },
  { label: "Success", value: "SUCCESS" },
];

const gatewayTypes = [
  { label: "HBL", value: "HBL" },
  { label: "CCAvenue", value: "CCAvenue" },
  { label: "ASC360 WALLET", value: "ASC360 WALLET" },
];

export const websitePaymentFilters = [
  {
    label: "Payment type",
    value: "payment_type",
    type: "checkbox",
    multiple: true,
    options: paymentTypes,
    roles: ["is_superuser"],
  },
  {
    label: "Status",
    value: "transaction_status",
    type: "checkbox",
    multiple: true,
    options: transactionStatus,
    roles: ["is_superuser"],
  },
  {
    label: "Gateway type",
    value: "gateway",
    type: "checkbox",
    multiple: true,
    options: gatewayTypes,
    roles: ["is_superuser"],
  },
];
