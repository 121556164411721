import useSWR from "swr";
import { fetcher } from "../../../services/fetcher";
import { USER_TYPES } from "../../../utils/userTypes";
import { useSelector } from "react-redux";

const useAssignPlan = (operatorEmail) => {
  const { user_type } = useSelector((state) => state.auth);
  const assignPlanUrl = user_type.includes(USER_TYPES.SUPERUSER)
    ? `issuance/assign-plan/?user_email=${operatorEmail}`
    : "issuance/assign-plan/";

  const { data, error, isLoading, mutate } = useSWR(assignPlanUrl, fetcher);

  return {
    plans: data,
    plansError: error,
    isPlansLoading: isLoading,
    mutatePlans: mutate,
  };
};

export default useAssignPlan;