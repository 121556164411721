import DataWrapper from "../../../../../../common/components/DataWrapper/DataWrapper";

import { websitePaymentFilters } from "../../utils/filters";

const endpoint = "paywall/website-payments";

const PaymentList = () => {
  const config = {
    fields: [
      { key: "gateway", label: "Gateway" },
      { key: "email", label: "User email" },
      {
        key1: "currency",
        key2: "total_amount",
        label: "Total Amount",
        type: "combine",
      },
      { key: "discount", label: "Discount" },
      { key: "transaction_id", label: "Transaction ID" },
      { key: "invoice_number", label: "Invoice No." },
      { key: "method", label: "Method" },
      { key: "payment_type", label: "Payment Type" },
      { key: "transaction_status", label: "Status" },
      { key: "created_on", label: "Created On" },
    ],
  };

  return (
    <>
      <DataWrapper
        endpoint={endpoint}
        config={config}
        filterOptions={websitePaymentFilters}
        useWebsiteFetcher={true}
      />
    </>
  );
};

export default PaymentList;
