import { useState, useCallback } from "react";

import { useDisclosure } from "@mantine/hooks";
import { Flex, Modal, Button } from "@mantine/core";

import { websiteFetcher } from "../../../../services/fetcher";

import DataWrapper from "../../../../../../common/components/DataWrapper/DataWrapper";
import AddViewEditFaqForm from "../../forms/AddViewEditFaqForm";
import ConfirmModal from "../../../../../../common/components/ConfirmModal/ConfirmModal";

import {
  successNotifications,
  errorNotifications,
} from "../../../../../../utils/handleNotifications";

const endpoint = "/faq/faqs/";

const FaqList = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [openedDelete, { open: openDelete, close: closeDelete }] =
    useDisclosure(false);

  const [selectFaq, setSelectFaq] = useState(null);
  const [mode, setMode] = useState(null);

  const handleFaqAction = async (data, mode) => {
    try {
      const url = mode === "create" ? endpoint : `${endpoint}${selectFaq.id}/`;

      const method = mode === "create" ? "POST" : "PATCH";

      await websiteFetcher(url, method, data);

      successNotifications(
        `Success! Promotion ${mode === "create" ? "Created" : "Updated"}`
      );
      close();
    } catch (error) {
      console.error(error);
      errorNotifications(error.response?.data, error);
    } finally {
      setSelectFaq(null);
      setMode("create");
    }
  };

  const onSubmit = (data) => {
    const mode = selectFaq ? "edit" : "create";
    handleFaqAction(data, mode);
  };

  const handleDelete = useCallback(async () => {
    if (!selectFaq) return;

    const { id: faq_id } = selectFaq;

    try {
      await websiteFetcher(`${endpoint}${faq_id}`, "DELETE");
      successNotifications("Faq deleted successfully");
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    } finally {
      setSelectFaq(null);
    }
  }, [selectFaq]);

  const handleViewFaq = (promotion) => {
    setSelectFaq(promotion);
    setMode("view");
    open();
  };

  const handleEditFaq = (promotion) => {
    setSelectFaq(promotion);
    setMode("edit");
    open();
  };

  const handleDeleteFaq = (promotion) => {
    setSelectFaq(promotion);
    openDelete();
  };

  const config = {
    fields: [
      { key: "category_display", label: "Category" },
      { key: "question", label: "Question" },
      { key: "answer", label: "Answer" },
    ],
    handler: [
      {
        label: "View",
        handler: (row) => handleViewFaq(row),
      },
      {
        label: "Edit",
        handler: (row) => handleEditFaq(row),
      },
      {
        label: "Delete",
        handler: (row) => handleDeleteFaq(row),
      },
    ],
  };

  return (
    <>
      <Flex
        mih={50}
        gap="md"
        justify="end"
        align="center"
        direction="row"
        wrap="wrap"
        mb="lg"
      >
        <Button
          variant="outline"
          onClick={() => {
            setSelectFaq(null);
            setMode("create");
            open();
          }}
        >
          + Create FAQ
        </Button>
      </Flex>

      <DataWrapper
        endpoint={endpoint}
        config={config}
        useWebsiteFetcher={true}
      />

      <Modal
        opened={opened}
        onClose={() => {
          close();
        }}
        size="50rem"
        style={{ textTransform: "capitalize" }}
        centered
        title={"Transactions"}
        closeButtonProps={{
          icon: (
            <img
              src="/images/svg/close.svg"
              style={{ width: "1.6rem" }}
              alt="close-icon"
            />
          ),
        }}
      >
        <AddViewEditFaqForm
          onSubmit={onSubmit}
          initialData={selectFaq}
          mode={mode}
        />
      </Modal>

      <ConfirmModal
        opened={openedDelete}
        onClose={closeDelete}
        title="Confirm Delete"
        content={`Are you sure you want to delete this promotion ?`}
        onCancel={closeDelete}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default FaqList;
