import nationalities from "../../../data/nationality.json";

const purchaseMethodOptions = [
  { label: "Quote Link", value: "LINK" },
  { label: "Dashboard", value: "OFF" },
  { label: "Website", value: "online" },
];

const policyStatus = [
  { label: "Issued", value: "ISSUED" },
  { label: "Pending", value: "PENDING" },
];

export const filterOptions = [
  {
    value: "nationality__name",
    label: "Nationality",
    type: "select",
    options: nationalities,
    roles: ["is_superuser"],
  },
  {
    value: "cover__id",
    label: "Cover",
    type: "select",
    options: nationalities,
    roles: ["is_superuser"],
  },
  {
    value: "date_range",
    label: "Date Range",
    type: "date-range",
    options: null,
    roles: ["is_superuser"],
  },
  {
    value: "document_verification_status",
    label: "Document Status",
    type: "switch",
    options: null,
    roles: ["is_superuser"],
  },
  {
    label: "Policy Status",
    value: "policy_status",
    type: "checkbox",
    multiple: true,
    options: policyStatus,
    roles: ["is_superuser"],
  },
  {
    label: "Purchase Method",
    value: "purchase_method",
    type: "checkbox",
    multiple: true,
    options: purchaseMethodOptions,
    roles: ["is_superuser"],
  },
];

export const sortOptions = [
  {
    label: "Sort By",
    value: "ordering",
    type: "checkbox",
    multiple: false,
    options: [
      { label: "Updated On", value: "updated_on" },
      { label: "Created On", value: "created_on" },
      { label: "Coverage Start Date", value: "coverage_start_date" },
      { label: "Coverage End Date", value: "coverage_end_date" },
      { label: "Total Amount", value: "total_amount" },
      { label: "No. of Pax", value: "no_of_pax" },
    ],
  },
];
