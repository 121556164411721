import ApexCharts from "react-apexcharts";

const TopSellingPlansChart = ({ data, isLoading }) => {
  if (isLoading) return <div>Loading...</div>;

  // Function to truncate cover names
  const truncateText = (text, maxLength = 10) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  // Prepare data for the chart
  const chartData = {
    series: [
      {
        name: "Bookings",
        data: data?.map((item) => item.booking) || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          borderRadius: {
            topLeft: 40, 
            topRight: 40, 
            bottomLeft: 0, 
            bottomRight: 0, 
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      grid: {
        show: true,
        strokeDashArray: 4,
      },
      xaxis: {
        categories: data?.map((item) => truncateText(item.cover_name)) || [],
        // title: {
        //   text: "Cover Names",
        // },
        labels: {
          rotate: -45,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        // title: {
        //   text: "Bookings",
        // },
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex }) {
          const fullName = data[dataPointIndex].cover_name;
          const bookings = series[seriesIndex][dataPointIndex];
          return `<div style="padding: 8px; border-radius: 4px; background: #f4f4f4; color: #333; font-size: 10px">
                    <strong>${fullName}</strong><br />
                    Bookings: ${bookings}
                  </div>`;
        },
      },
      colors: ["#2DC148"],  
    },
  };

  return (
    <div>
      <ApexCharts
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={274}
      />
    </div>
  );
};

export default TopSellingPlansChart;