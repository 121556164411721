import { Routes, Route, Navigate } from "react-router-dom";
import UsersPage from "../features/users/pages/Users/Users";
import AddUsersPage from "../features/users/containers/AddUsers/AddUsersPage";
import UserPage from "../features/users/containers/User/User";
import AdventuresPage from "../features/covers/adventures/pages/AdventuresPage";
import AddViewEditAdventure from "../features/covers/adventures/containers/AddViewEditAdventure";
import MotherCover from "../features/covers/pages/MotherCover/MotherCover";
import AddMotherCover from "../features/covers/containers/AddMotherCover/AddMotherCover";
import ViewEditMotherCover from "../features/covers/containers/ViewEditMotherCover/ViewEditMotherCover";
import MasterCover from "../features/covers/pages/MasterCover/MasterCover";
import AddMasterCover from "../features/covers/containers/AddMasterCover/AddMasterCover";
import ViewEditMasterCover from "../features/covers/containers/ViewEditMasterCover/ViewEditMasterCover";
import CustomizeCover from "../features/covers/pages/CustomizeCover/CustomizeCover";
import AddCustomizeCover from "../features/covers/containers/AddCustomizeCover/AddCustomizeCover";
import ViewEditCustomizeCover from "../features/covers/containers/ViewEditCustomizeCover/ViewEditCustomizeCover";
import Wallet from "../features/users/pages/Wallet/Wallet";
import AssignCover from "../features/users/pages/AssignCover/AssignCover";
import UserLayout from "../features/users/layout/UserLayout";
import OperatorLayout from "../features/operators/layout/OperatorLayout";
import Operators from "../features/operators/pages/Operators/Operators";
import AddOperator from "../features/operators/containers/AddOperator/AddOperator";
import OperatorProfile from "../features/operators/containers/OperatorProfile/OperatorProfile";
import OperatorWallet from "../features/operators/containers/Wallet/OperatorWallet";
import AssignCoverOperator from "../features/operators/containers/AssignCoverOperator/AssignCoverOperator";
import Commission from "../features/operators/containers/Commission/Commission";
import Issuance from "../features/issuance/pages/Issuance/Issuance";
import TravelerInfo from "../features/issuance/containers/SingleIssuance/TravelerInfo";
import OperatorPayments from "../features/operators/containers/Payments/Payments";
import OperatorIssuedPolicies from "../features/operators/containers/PoliciesIssued/PoliciesIssued";
import Policies from "../features/issuance/pages/Policies/Policies";
import TripDetails from "../features/issuance/containers/TripTravellerDetail/TripDetails";
import VendorsPage from "../features/vendors/pages/VendorsPage";
import AddVendor from "../features/vendors/container/vendorList/AddVendor/AddVendor";
import EditVendor from "../features/vendors/container/vendorList/EditVendor/EditVendor";
import ViewVendor from "../features/vendors/container/vendorList/ViewVendor/ViewVendor";
import MasterWallet from "../features/master-wallet/pages/MasterWallet/MasterWallet";
import CoverPricing from "../features/covers/pricing/pages/CoverPricing";
import QuoteLinks from "../features/issuance/pages/QuoteLinks/QuoteLinks";
import UserPayments from "../features/users/pages/Payments/Payments";
import Benefits from "../features/covers/benefits/pages/Benefits/Benefits";
import CoverBenefits from "../features/covers/benefits/pages/CoverBenefits/CoverBenefits";
import UserIssuedPolicies from "../features/users/pages/UserIssuedPolicies/UserIssuedPolicies";
import UnissuedPolicies from "../features/issuance/pages/UnissuedPolicies/UnissuedPolicies";

// Payments
import Payments from "../features/payments/pages/Payments";
import TravellerDetails from "../features/issuance/containers/TripTravellerDetail/TravellerDetails";
import Endorsement from "../features/issuance/containers/Endorsement/Endorsement";
import Trips from "../features/policies/pages/Trips/Trips";
import Travellers from "../features/policies/pages/Travellers/Travellers";

// Policies

//
import UserWallets from "../features/user-wallet/pages/UserWallets/UserWallets";
import WalletTransactions from "../features/user-wallet/pages/WalletTransactions/WalletTransactions";

// Website
import PromotionList from "../features/website/features/promotion/pages/PromotionList/Promotion";
import FaqList from "../features/website/features/faq/pages/FaqList/FaqList";
import CoverEnquiryList from "../features/website/features/cover-enquiry/pages/CoverEnquiryList/CoverEnquiryList";
import PaymentList from "../features/website/features/payment/pages/PaymentList/PaymentList";

import Bookings from "../features/booking/pages/Bookings";

const SuperuserRoutes = () => (
  <Routes>
    <Route path="users" element={<UsersPage />} />
    <Route path="users/add" element={<AddUsersPage />} />
    <Route path="users/:id" element={<UserLayout />}>
      <Route path="profile" element={<UserPage mode={"edit"} />} />
      <Route path="wallet" element={<Wallet />} />
      <Route path="covers" element={<AssignCover />} />
      <Route path="payments" element={<UserPayments />} />
      <Route path="policies-issued" element={<UserIssuedPolicies />} />
      <Route path="policies-issued/:id" element={<TripDetails />} />
    </Route>

    <Route path="covers">
      <Route index element={<Navigate to="adventures" replace />} />
      <Route path="adventures" element={<AdventuresPage />} />
      <Route
        path="adventures/add"
        element={<AddViewEditAdventure mode={"add"} />}
      />
      <Route
        path="adventures/:id"
        element={<AddViewEditAdventure mode={"view"} />}
      />
      <Route
        path="adventures/edit/:id"
        element={<AddViewEditAdventure mode={"edit"} />}
      />

      {/* Mother Covers */}
      <Route path="mother-covers" element={<MotherCover />} />
      <Route path="mother-covers/add" element={<AddMotherCover />} />
      <Route
        path="mother-covers/:id"
        element={<ViewEditMotherCover mode={"view"} />}
      />
      <Route
        path="mother-covers/edit/:id"
        element={<ViewEditMotherCover mode={"edit"} />}
      />

      {/* Master Covers */}
      <Route path="master-covers" element={<MasterCover />} />
      <Route
        path="master-covers/add"
        element={<AddMasterCover mode={"add"} />}
      />
      <Route
        path="master-covers/:id"
        element={<ViewEditMasterCover mode={"view"} />}
      />
      <Route
        path="master-covers/edit/:id"
        element={<ViewEditMasterCover mode={"edit"} />}
      />

      {/* Customize Covers */}
      <Route path="customize-covers" element={<CustomizeCover />} />
      <Route
        path="customize-covers/add"
        element={<AddCustomizeCover mode={"add"} />}
      />
      <Route
        path="customize-covers/:id"
        element={<ViewEditCustomizeCover mode={"view"} />}
      />
      <Route
        path="customize-covers/edit/:id"
        element={<ViewEditCustomizeCover mode={"edit"} />}
      />

      {/* Pricing */}
      <Route
        path=":planType/:parentId/pricing/"
        element={<CoverPricing mode={"add"} />}
      />

      <Route
        path=":planType/:parentId/pricing/edit"
        element={<CoverPricing mode={"edit"} />}
      />

      {/* Cover Benefits */}
      <Route path="benefits" element={<Benefits />} />
      <Route
        path=":planType/:coverID/benefits"
        element={<CoverBenefits mode={"add"} />}
      />
      <Route
        path=":planType/:coverID/benefits/edit"
        element={<CoverBenefits mode={"edit"} />}
      />
    </Route>

    <Route>
      <Route path="vendor" element={<VendorsPage />} />
      <Route path="vendor/add" element={<AddVendor />} />
      <Route path="vendor/edit/:id" element={<EditVendor />} />
      <Route path="vendor/view/:id" element={<ViewVendor />} />
    </Route>

    {/* Master Wallet */}
    <Route path="master-wallet">
      <Route index element={<MasterWallet />} />
    </Route>

    {/* Issuance */}
    <Route path="issuance">
      <Route path=":policy_type" element={<Issuance />} />
      <Route path=":policy_type/traveller" element={<TravelerInfo />} />
      <Route path="policies" element={<Policies />} />
      <Route path="unissued-policies" element={<UnissuedPolicies />} />
      <Route path="policies/trips/:id" element={<TripDetails />} />
      <Route path="policies/travellers/:id" element={<TravellerDetails />} />
      <Route path="quote-links" element={<QuoteLinks />} />
      <Route path="endorsement/:id" element={<Endorsement />} />
    </Route>

    {/* Operator management */}
    <Route path="operators" element={<Operators />} />
    <Route path="operators/add" element={<AddOperator />} />
    <Route path="operators/:uuid" element={<OperatorLayout />}>
      <Route path="profile" element={<OperatorProfile />} />
      <Route path="wallet" element={<OperatorWallet />} />
      <Route path="covers" element={<AssignCoverOperator />} />
      <Route path="commission" element={<Commission />} />
      <Route path="payments" element={<OperatorPayments />} />
      <Route path="policies-issued" element={<OperatorIssuedPolicies />} />
      <Route path="policies-issued/:id" element={<TripDetails />} />
    </Route>

    {/* Payments */}
    <Route path="all-payments" element={<Payments />} />

    {/* Policies */}
    <Route path="policies">
      <Route path="trips" element={<Trips />} />
      <Route path="travellers" element={<Travellers />} />
    </Route>

    <Route path="wallet">
      <Route path="user-wallets" element={<UserWallets />} />
      <Route path="wallet-transactions" element={<WalletTransactions />} />
    </Route>

    <Route path="website">
      <Route path="faqs" element={<FaqList />} />
      <Route path="promotions" element={<PromotionList />} />
      <Route path="cover-enquiries" element={<CoverEnquiryList />} />
      <Route path="payments" element={<PaymentList />} />
    </Route>

    <Route path="asc-booking">
      <Route path="bookings" element={<Bookings />} />
    </Route>
  </Routes>
);

export default SuperuserRoutes;
