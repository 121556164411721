import React from "react";
import { Menu, Button, Image, Checkbox, Divider, Group } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import classes from "./Filtermenu.module.css";

const getCurrentYear = () => new Date().getFullYear();
const getCurrentMonth = () =>
  new Date().toLocaleString("default", { month: "short" });

const FilterMenu = ({
  id,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setSelectedYear,
  setSelectedMonth,
  hideMonthYear = false,
  hideDateFilters = false,
  onFilterChange, 
}) => {
  const [useCurrentYear, setUseCurrentYear] = React.useState(false);
  const [useCurrentMonth, setUseCurrentMonth] = React.useState(false);
  const [opened, setOpened] = React.useState(false);

  const handleDateChange = (setter, value) => {
    setter(value);
    onFilterChange(id, { startDate, endDate });
  };

  const handleYearChange = (checked) => {
    setUseCurrentYear(checked);
    const year = checked ? getCurrentYear().toString() : null;
    setSelectedYear(year);
    onFilterChange(id, { year });
  };

  const handleMonthChange = (checked) => {
    setUseCurrentMonth(checked);
    const month = checked ? getCurrentMonth().toLowerCase() : null;
    setSelectedMonth(month);
    onFilterChange(id, { month });
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedYear(null);
    setSelectedMonth(null);
    setUseCurrentYear(false);
    setUseCurrentMonth(false);
    
    // Trigger the filter change callback to reset filters
    onFilterChange(id, { startDate: null, endDate: null, year: null, month: null });
  };

  const handleCloseMenu = () => {
    setOpened(false); // Close the menu when the close icon is clicked
  };

  return (
    <Menu closeOnClickOutside={false} closeOnItemClick={false} opened={opened} onClose={() => setOpened(false)}>
      <Menu.Target>
        <Button style={{border: "none", background: "none"}} onClick={() => setOpened(true)}>
          <Image
            src="/images/svg/filter.svg"
            style={{ width: "10px" }}
            alt="filter-icon"
          />
        </Button>
      </Menu.Target>
      <Menu.Dropdown className={classes.Filter}>
        <div className={classes.FilterMenu}>
          <div className={classes.head}>
            <h4 className={classes.heading}>Filter</h4>
            <Image
                src="/images/svg/close.svg"
                style={{ height: "24px", width: "24px", marginTop: "2px", cursor: "pointer"}}
                alt="close-icon"
                onClick={handleCloseMenu}
            />
          </div>
          <Divider/>
          <div className={classes.options}>
            {!hideDateFilters && (
              <>
                <DatePickerInput
                  label="Start Date"
                  placeholder="Select start date"
                  value={startDate}
                  onChange={(value) => handleDateChange(setStartDate, value)}
                  maxDate={new Date()}
                  styles={{
                    label: {fontSize: "14px", lineHeight: "2px", fontWeight: "500"},
                    input: {
                      height: "10px",
                      padding: "4px 8px", 
                      fontSize: "10px", 
                    },    
                  }}
                />
                <DatePickerInput
                  label="End Date"
                  placeholder="Select end date"
                  value={endDate}
                  onChange={(value) => handleDateChange(setEndDate, value)}
                  minDate={startDate}
                  maxDate={new Date()}
                  styles={{
                    label: {fontSize: "14px", lineHeight: "2px", fontWeight: "500"},
                    input: {
                      // height: "10px",
                      // padding: "6px 8px", 
                      fontSize: "10px", 
                      width: "150px"
                    }, 
                  }}
                />
              </>
            )}
            {!hideMonthYear && (
              <>
                <Checkbox
                  label="Year"
                  checked={useCurrentYear}
                  onChange={(event) => handleYearChange(event.currentTarget.checked)}
                  mt="xs"
                  styles={{
                    label: {fontSize: "14px", fontWeight: "500"}, 
                  }}
                />
                <Checkbox
                  label="Month"
                  checked={useCurrentMonth}
                  onChange={(event) => handleMonthChange(event.currentTarget.checked)}
                  mt="xs"
                  styles={{
                    label: {fontSize: "14px", fontWeight: "500"}, 
                  }}
                />
              </>
            )}
            <Group justify="center">
              <Button variant="gradient" mt="md" onClick={handleReset}>
                 Reset
              </Button>
            </Group>
          </div>
        </div>
      </Menu.Dropdown>
    </Menu>
  );
};

export default FilterMenu;