import DataWrapper from "../../../../../../common/components/DataWrapper/DataWrapper";

const endpoint = "covers/enquires";

const CoverEnquiryList = () => {
  const config = {
    fields: [
      { key: "adventure", label: "Adventure" },
      { key: "name", label: "Person Name" },
      { key: "email", label: "Person Email" },
      { key: "mobile", label: "Person Mobile" },
      { key: "nationality", label: "Nationality" },
      { key: "age", label: "Age" },
      { key: "coverage_start_date", label: "Start Date" },
      { key: "coverage_end_date", label: "End Date" },
      { key: "destination", label: "Destination" },
      { key: "is_email_verified", label: "Is Verified" },
    ],
  };

  return (
    <>
      <DataWrapper
        endpoint={endpoint}
        config={config}
        useWebsiteFetcher={true}
      />
    </>
  );
};

export default CoverEnquiryList;
