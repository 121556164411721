import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import DataWrapper from "../../../../common/components/DataWrapper/DataWrapper";

const endpoint = "issuance/user-specific-payments/";

const OperatorPayments = () => {
  const outletContext = useOutletContext();
  const operator = outletContext ? outletContext.operator : null;
  const operatorProfile = useSelector((state) => state.operatorProfile);

  const operatorEmail = operator
    ? operator.operator.email
    : operatorProfile.operator.email;

  const paymentsEndpoint = `${endpoint}?user_email=${operatorEmail}`;

  const paymentConfig = {
    fields: [
      { key: "transaction_id", label: "Transaction ID" },
      { key: "gateway", label: "Gateway" },
      { key: "method", label: "Method" },
      { key: "currency", label: "Currency" },
      { key: "total_amount", label: "Total Amount" },
      { key: "transaction_status", label: "Status", type: "status" },
    ],
  };

  return (
    <>
      <DataWrapper endpoint={paymentsEndpoint} config={paymentConfig} />
    </>
  );
};

export default OperatorPayments;
