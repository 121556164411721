import { useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { Controller } from "react-hook-form";
import useSWR from "swr";
import { fetcher } from "../../../services/fetcher";
import { websiteFetcher } from "../../../features/website/services/fetcher";
import { Combobox, Loader, TextInput, useCombobox } from "@mantine/core";

/**
 * @param {string} queryKey - The key name for the dynamic query parameter used in the API request.
 * Example: "query" or "search".
 * The value for this key (e.g., debounced input) is handled internally in the child component.
 */

/**
 * @param {object} queryParams - An object containing additional static query parameters to include in the API request.
 * These parameters remain constant and are merged with the dynamic query parameter.
 * Example: { status: "active", sort: "name" }.
 */

/**
 * @param {string} endpoint - The base API endpoint to which query parameters are appended dynamically.
 * Example: "/api/users".
 */

/**
 * @param {function} getOptionValue - A function that extracts a unique value from each data item for the dropdown options.
 * Example: (item) => item.id.toString().
 */

/**
 * @param {function} getOptionLabel - A function that extracts the display label from each data item for the dropdown options.
 * Example: (item) => `${item.first_name} ${item.last_name}`.
 */

/**
 * @param {object} control - The React Hook Form control object for managing the input's state and validation.
 * Passed directly to the `Controller` component for form handling.
 */

/**
 * @param {string} name - The name of the form field managed by React Hook Form.
 * This should match the field's name in your form schema.
 */

export function AsyncAutocomplete({
  endpoint,
  getOptionValue,
  getOptionLabel,
  control,
  name,
  queryKey,
  queryParams,
  useWebsiteFetcher = false,
}) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [inputValue, setInputValue] = useState("");
  const [debouncedQuery] = useDebouncedValue(inputValue, 400);
  const [empty, setEmpty] = useState(false);

  const selectedFetcher = useWebsiteFetcher ? websiteFetcher : fetcher;

  const { data, isLoading } = useSWR(
    debouncedQuery
      ? `${endpoint}?${new URLSearchParams({
          [queryKey]: debouncedQuery,
          ...queryParams,
        }).toString()}`
      : null,
    selectedFetcher,
    {
      onSuccess: (data) => {
        setEmpty((data.results.length || data.length) === 0);
      },
      onError: () => {
        setEmpty(true);
      },
    }
  );

  const options = (data?.results || data || []).map((item) => (
    <Combobox.Option key={getOptionValue(item)} value={getOptionValue(item)}>
      {getOptionLabel(item)}
    </Combobox.Option>
  ));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Combobox
          onOptionSubmit={(optionValue) => {
            const selected = (data?.results || data || []).find(
              (item) => getOptionValue(item) === optionValue
            );
            if (selected) {
              setInputValue(getOptionLabel(selected));
              field.onChange(getOptionValue(selected));
            }
            combobox.closeDropdown();
          }}
          withinPortal={false}
          store={combobox}
        >
          <Combobox.Target>
            <TextInput
              label="Select User"
              placeholder="Search users"
              value={inputValue}
              onChange={(event) => {
                const inputValue = event.currentTarget.value;
                setInputValue(inputValue);
                combobox.resetSelectedOption();
                combobox.openDropdown();
              }}
              onClick={() => combobox.openDropdown()}
              onFocus={() => combobox.openDropdown()}
              onBlur={() => combobox.closeDropdown()}
              rightSection={isLoading && <Loader size={18} />}
            />
          </Combobox.Target>

          <Combobox.Dropdown hidden={!data && !isLoading}>
            <Combobox.Options>
              {options}
              {!isLoading && empty && (
                <Combobox.Empty>No results found</Combobox.Empty>
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      )}
    />
  );
}
