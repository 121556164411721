import { useForm, Controller } from "react-hook-form";
import {
  Select,
  SimpleGrid,
  Group,
  Button,
  NumberInput,
  Textarea,
  FileInput,
} from "@mantine/core";

import withFormattedOptions from "../../../../common/hoc/withFormattedOptions/withFormattedOptions";

import {
  BookingStatus,
  BookingPriority,
  PaymentStatus,
  DocumentStatus,
  BookingReceived,
} from "../../utils/bookingOptions";

const EnhancedSelect = withFormattedOptions(Select);

const BookingForm = ({ onSubmit, userProfiles }) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid cols={2}>
        {/* After booking created */}
        <Controller
          name="issuance_representative"
          control={control}
          rules={{ required: "issuance representative is required" }}
          render={({ field }) => (
            <EnhancedSelect
              label="Issuance Representative"
              placeholder="Select issuance representative"
              searchable
              data={userProfiles}
              onChange={field.onChange}
              value={field.value}
              getOptionValue={(item) => item.user_profile_info.id.toString()}
              getOptionLabel={(item) => item.user_profile_info.user.email}
              error={errors.issuance_representative?.message}
            />
          )}
        />

        {/* After booking created */}
        <Controller
          name="booking_status"
          control={control}
          rules={{ required: "booking status is required" }}
          render={({ field }) => (
            <Select
              label="Status"
              withAsterisk
              placeholder="Select booking status"
              data={BookingStatus}
              error={errors.booking_status?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          name="discount"
          control={control}
          render={({ field }) => (
            <NumberInput
              label="Discount"
              placeholder="Enter discount"
              decimalScale={2}
              max={99}
              allowNegative={false}
              hideControls
              {...field}
            />
          )}
        />

        <Controller
          name="priority"
          control={control}
          rules={{ required: "priority is required" }}
          render={({ field }) => (
            <Select
              label="Priority"
              withAsterisk
              placeholder="Select priority"
              data={BookingPriority}
              error={errors.priority?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          name="payment_status"
          control={control}
          rules={{ required: "payment_status is required" }}
          render={({ field }) => (
            <Select
              label="Payment Status"
              withAsterisk
              placeholder="Select payment status"
              data={PaymentStatus}
              error={errors.payment_status?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          name="document_status"
          control={control}
          rules={{ required: "document status is required" }}
          render={({ field }) => (
            <Select
              label="Document Status"
              withAsterisk
              placeholder="Select document status"
              data={DocumentStatus}
              error={errors.document_status?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          name="booking_received"
          control={control}
          rules={{ required: "booking received is required" }}
          render={({ field }) => (
            <Select
              label="Booking received"
              withAsterisk
              placeholder="Select booking received"
              data={BookingReceived}
              error={errors.booking_received?.message}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          name="csv_file"
          control={control}
          render={({ field }) => (
            <FileInput
              label="CSV File"
              placeholder="Upload csv file"
              clearable
              accept="text/csv"
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          name="payment_receipt"
          control={control}
          render={({ field }) => (
            <FileInput
              label="Payment Receipt"
              placeholder="Upload payment receipt"
              clearable
              accept="image/png,image/jpeg,application/pdf"
              onChange={field.onChange}
            />
          )}
        />

        <Textarea
          label="Remarks"
          placeholder="Enter remarks"
          {...register("remarks")}
        />
      </SimpleGrid>

      <Group justify="center">
        <Button variant="gradient" type="submit" mt="xl" size="md">
          {"Submit"}
        </Button>
      </Group>
    </form>
  );
};

export default BookingForm;
